import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { userService } from '../_services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useTranslation } from "react-i18next";



const TermsConditions = (props: any) => {
    const { t, i18n } = useTranslation();
    const [content, setContent] = useState('<p>Hello from CKEditor 5!</p>');
    const [language, setLanguage] = useState(localStorage.getItem("selectLanguage") == "ENGLISH" ? "en" : "fr");

    useEffect(() => getTerms(language), []);

    useEffect(() => {
        var lang = localStorage.getItem("selectLanguage") == "ENGLISH" ? "en" : "fr"
        setLanguage(lang)
        getTerms(lang)
    }, [localStorage.getItem("selectLanguage")]);

    function getTerms(language) {
        setLanguage(language)
        userService.getTerms(language)
            .then(response => {
                console.log(response.data)
                setContent(response.data.data.description)
            })
            .catch(error => {
                console.log(error.response)
            });
    }


    function updateTerms() {
        if (!content) {
            toast.error(t("Please enter Terms & Conditions."))
        }
        else {
            var params = {
                description: content
            }
            userService.updateTerms(params, language)
                .then(response => {
                    console.log("response", response)
                    toast.success(t("Terms and conditions updated successfully."))
                    getTerms(language);
                })
                .catch(error => {
                    console.log(error.response)
                });
        }
    }



    return (

        <section>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="page_header privacy-policy">
                            <h2 className="subtitle">{t("Terms & Conditions")}</h2>
                            {/* <select
                        className="form-select"
                        onChange={(e) => getTerms(e.target.value)}
                        value={language}
                    >
                        <option value="en">English</option>
                        <option value="fr">French</option>
                    </select> */}

                        </div>
                        <div className="App">
                            <CKEditor
                                editor={ClassicEditor}
                                data={content}
                                onChange={(event, editor) => { content.length == 0 ? setContent(editor.getData().replace(/ /g, "")) : setContent(editor.getData()) }}
                            />
                        </div>
                        <button className="save-bt sumbit-btn-bottom" onClick={updateTerms}>{t("Submit")}</button>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </section>

    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(TermsConditions)