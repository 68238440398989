import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { userService } from '../_services';
import { useTranslation } from "react-i18next";


const Adminprofile = (props: any) => {
    const { t, i18n } = useTranslation();
    const [checked, setchecked] = useState(true);
    const [userprofile, setuserprofile] = useState({ name: "", email: "", profile_image: "", birthdate: "", location: "", bio:"", categories:"",subcategories:"",profile_photo:"" });
    const [userid, setUserid] = useState('');

    function handleChange() {
        setchecked(!checked)
    }


  

    useEffect(() =>  callAdminProfileAPI(), []);

  

    function callAdminProfileAPI() {
        userService.adminProfile()
            .then(response => {
                console.log(response.data)
                setuserprofile(response.data.admin)
                
            })
            .catch(error => {
                console.log(error.response)
            });
    }

    const { error, handleSubmit, pristine, reset, submitting } = props
    return (
        <div>
            <h2>{t("Profile")}</h2>
            <div className="white_box my_profile">
                <div className="row">
                    <aside className="col-lg-4 text-center">
                        <img src={userprofile.profile_photo} alt="" className="img-circle profile_image" />
                    </aside>
                    <aside className="col-lg-8">
                        <div className="row">
                            <aside className="col-sm-6">
                            <div className="user_name padding_top_80">
                                <label>{t("Name")}</label>
                                <h4>{userprofile.name}</h4>
                            </div>
                            </aside>
                            <aside className="col-sm-6">
                                <div className="user_name padding_top_80">
                                <label>{t("Email Address")}</label>
                                <h4>{userprofile.email}</h4>
                                </div>
                            </aside>

                        </div>
                    </aside>

                </div>
            </div>
        </div>
    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(Adminprofile)