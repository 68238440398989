export function authHeader() {
 
    // return authorization header with basic auth credentials
    let user = localStorage.getItem('access_token')

    if (user && user) {
        return {  'deviceType':'w','appVersion' : '1.0','Authorization': 'Bearer ' + user};
    } else {
        return {};
    }
}