import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
// Importing Images
import profile_image from '../assets/images/profile_image.jpg';
import { userService } from '../_services';
import Pagination from "react-js-pagination";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import moment from 'moment';


const JobsManagement = (props: any) => {
    const { t, i18n } = useTranslation();
    const [allJobs, setallJobs] = useState([{ _id: "", title: "", user_id: { name: "", last_name: "" }, hiredBidder: { name: "", last_name: "" }, category: { name: "" }, complete_status: false, start_date: "", status: "", finalPrice: 0 }]);
    const [pendingJobs, setpendingJobss] = useState([{ _id: "", title: "", user_id: { name: "", last_name: "" }, hiredBidder: { name: "", last_name: "" }, category: { name: "" }, complete_status: false, start_date: "", status: "", finalPrice: 0 }]);
    const [hiredJobs, sethiredJobs] = useState([{ _id: "", title: "", user_id: { name: "", last_name: "" }, hiredBidder: { name: "", last_name: "" }, category: { name: "" }, complete_status: false, start_date: "", status: "", finalPrice: 0 }]);

    const [backupallJobs, setbackupallJobs] = useState([{ _id: "", title: "", user_id: { name: "", last_name: "" }, hiredBidder: { name: "", last_name: "" }, category: { name: "" }, complete_status: false, start_date: "", status: "", finalPrice: 0 }])
    const [backuppendingJobs, setbackuppendingJobs] = useState([{ _id: "", title: "", user_id: { name: "", last_name: "" }, hiredBidder: { name: "", last_name: "" }, category: { name: "" }, complete_status: false, start_date: "", status: "", finalPrice: 0 }])
    const [backuphiredJobs, setbackuphiredJobs] = useState([{ _id: "", title: "", user_id: { name: "", last_name: "" }, hiredBidder: { name: "", last_name: "" }, category: { name: "" }, complete_status: false, start_date: "", status: "", finalPrice: 0 }])

    const [search, setsearch] = useState('');
    const [search2, setsearch2] = useState('');
    const [search3, setsearch3] = useState('');

    const [fullData, setfullData] = useState([])
    const [perPage, setperPage] = useState(10)
    const [currentPage, setcurrentPage] = useState(1)
    const [fullData2, setfullData2] = useState([])
    const [perPage2, setperPage2] = useState(10)
    const [currentPage2, setcurrentPage2] = useState(1)
    const [fullData3, setfullData3] = useState([])
    const [perPage3, setperPage3] = useState(10)
    const [currentPage3, setcurrentPage3] = useState(1)
    const [isLoading, setisLoading] = useState(1);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    // useEffect(() => callJobsAPI(), []);
    useEffect(() => {
        callJobsAPI(startDate, endDate)
    }, [])


    const resetDates = () => {
        callJobsAPI("", "");
        setStartDate('');
        setEndDate('');
    };

    function callJobsAPI(startDate: string, endDate: string) {
        const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
        const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';
        const params = { startDate: startDate, endDate: endDate };
        if (formattedStartDate) params.startDate = formattedStartDate;
        if (formattedEndDate) params.endDate = formattedEndDate;
        userService.jobsmanagement(params)
            .then(response => {
                console.log(response.data)
                console.log(response.data)
                var data = response.data.jobsAll;
                var newdata = [] as any;
                for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                    if (data[i]) {
                        newdata.push(data[i]);
                    }
                }
                setallJobs(newdata)
                setfullData(data);
                setbackupallJobs(data)

                var data2 = response.data.pendingJobs;
                var newdata2 = [] as any;
                for (var i = (0 * perPage2); i < ((0 * perPage2) + perPage2); i++) {
                    if (data2[i]) {
                        newdata2.push(data2[i]);
                    }
                }
                console.log("newdata2", newdata2)
                setpendingJobss(newdata2)
                setfullData2(data2);
                setbackuppendingJobs(data2)


                var data3 = response.data.hiredJobs;
                var newdata3 = [] as any;
                for (var i = (0 * perPage3); i < ((0 * perPage3) + perPage3); i++) {
                    if (data3[i]) {
                        newdata3.push(data3[i]);
                    }
                }
                console.log("newdata3", newdata3)
                sethiredJobs(newdata3)
                setfullData3(data3);
                setbackuphiredJobs(data3)
                setisLoading(2)
                // setallJobs(data.jobsAll);
                // setpendingJobss(data.pendingJobs);
                // sethiredJobs(data.hiredJobs);
            })
            .catch(error => {
                console.log(error.response)
            });
    }

    function handlePageChange(pageNumber) {
        console.log(pageNumber)
        var newdata = [] as any;
        for (var i = ((pageNumber - 1) * perPage); i < (((pageNumber - 1) * perPage) + perPage); i++) {
            if (fullData[i]) {
                console.log(fullData[i])
                newdata.push(fullData[i]);
            }
        }
        setcurrentPage(pageNumber)
        setallJobs(newdata)
    }


    function handlePageChange2(pageNumber) {
        console.log(pageNumber)
        var newdata = [] as any;
        for (var i = ((pageNumber - 1) * perPage); i < (((pageNumber - 1) * perPage) + perPage); i++) {
            if (fullData2[i]) {
                console.log(fullData2[i])
                newdata.push(fullData2[i]);
            }
        }
        setcurrentPage2(pageNumber)
        setpendingJobss(newdata)
    }


    function handlePageChange3(pageNumber) {
        console.log(pageNumber)
        var newdata = [] as any;
        for (var i = ((pageNumber - 1) * perPage); i < (((pageNumber - 1) * perPage) + perPage); i++) {
            if (fullData3[i]) {
                console.log(fullData3[i])
                newdata.push(fullData3[i]);
            }
        }
        setcurrentPage3(pageNumber)
        sethiredJobs(newdata)
    }


    function disable(item, status) {
        let result;
        if (status == "DEACT") {
            result = window.confirm(t("Are you sure you want to de-activate this job?"))
        }
        else {
            result = window.confirm(t("Are you sure you want to activate this job?"))
        }

        if (result) {
            setisLoading(1)
            var params = {
                job_id: item._id,
                status: status
            }
            userService.changeStatusJob(params)
                .then(response => {
                    console.log("response.user", response)
                    setisLoading(2)
                    setTimeout(
                        () => {
                            toast.success(status == "DEACT" ? t("De-activate successfully") : t("Activated Successfully"))
                        },
                        500
                    );
                    callJobsAPI(startDate, endDate);
                })
                .catch(error => {
                    console.log(error.response)
                });
        }
    }

    function goToJobDetail(item) {
        window.location.href = '/jobdetail/' + item._id;
    }



    function onSearchChanged(event) {
        setsearch(search.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        if (search.length) {
            var searchData = [] as any;
            for (var j in backuppendingJobs) {
                var fullname = backuppendingJobs[j].user_id.name + " " + backuppendingJobs[j].user_id.last_name;
                if (backuppendingJobs[j].title.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backuppendingJobs[j].user_id.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backuppendingJobs[j].user_id.last_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    fullname.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backuppendingJobs[j].start_date.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backuppendingJobs[j].category.name.toLowerCase().includes(event.target.value.toLowerCase())) {
                    searchData.push(backuppendingJobs[j]);
                }
            }
            var data = searchData;
            var newdata = [] as any;
            for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                if (data[i]) {
                    newdata.push(data[i]);
                }
            }
            setpendingJobss(newdata)
            setfullData(data);
            setcurrentPage(1)
        }
    }


    function onSearchChanged2(event) {
        setsearch2(search2.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        if (search2.length) {
            var searchData = [] as any;
            for (var j in backuphiredJobs) {
                var fullnameCus = backuphiredJobs[j].user_id.name + " " + backuphiredJobs[j].user_id.last_name;
                var fullnameWorker = backuphiredJobs[j].hiredBidder.name + " " + backuphiredJobs[j].hiredBidder.last_name;
                if (backuphiredJobs[j].title.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backuphiredJobs[j].user_id.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backuphiredJobs[j].user_id.last_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    fullnameCus.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backuphiredJobs[j].hiredBidder.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backuphiredJobs[j].hiredBidder.last_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    fullnameWorker.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backuphiredJobs[j].category.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backuphiredJobs[j].start_date.toLowerCase().includes(event.target.value.toLowerCase())) {
                    searchData.push(backuphiredJobs[j]);
                }
            }
            var data = searchData;
            var newdata = [] as any;
            for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                if (data[i]) {
                    newdata.push(data[i]);
                }
            }
            sethiredJobs(newdata)
            setfullData2(data);
            setcurrentPage2(1)
        }
    }


    function onSearchChanged3(event) {
        setsearch3(search3.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        if (search3.length) {
            var searchData = [] as any;
            for (var j in backupallJobs) {
                var fullnameCus = backupallJobs[j].user_id.name + " " + backupallJobs[j].user_id.last_name;
                var fullnameWorker = backupallJobs[j].hiredBidder.name + " " + backupallJobs[j].hiredBidder.last_name;

                if (backupallJobs[j].title.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupallJobs[j].user_id.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupallJobs[j].user_id.last_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    fullnameCus.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupallJobs[j].hiredBidder.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupallJobs[j].hiredBidder.last_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupallJobs[j].category.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    fullnameWorker.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupallJobs[j].start_date.toLowerCase().includes(event.target.value.toLowerCase())) {
                    searchData.push(backupallJobs[j]);
                }
            }
            var data = searchData;
            var newdata = [] as any;
            for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                if (data[i]) {
                    newdata.push(data[i]);
                }
            }
            setallJobs(newdata)
            setfullData3(data);
            setcurrentPage3(1)
        }
    }




    const { error, handleSubmit, pristine, reset, submitting } = props
    return (
        <section className="my_job_tab_box">
            {isLoading == 1 &&
                <div className="loader-back">
                    <div className="loaderrrr">
                        <span>
                            <img src={require("../assets/images/loader.gif")} alt="" />
                        </span>

                    </div>
                </div>
            }
            <div className="tab_heading_content">
                <ul className="nav nav-tabs">
                    <li className="active active_list"><a data-toggle="tab" href="#approved">{t("Open")} <span>{backuppendingJobs.length}</span></a></li>
                    <li className="upcoming_list"><a data-toggle="tab" href="#paid">{t("Hired")} <span>{backuphiredJobs.length}</span></a></li>
                    <li className="past-list"><a data-toggle="tab" href="#completed">{t("Completed")} {<span>{backupallJobs.length}</span>}</a></li>
                </ul>
            </div>
            <div className="tab-content">

                <div id="approved" className="tab-pane fade in active">
                    <div className="tables_area">
                        <h2 className="pull-left">{t("Open Jobs")}</h2>
                        <div className="input-group search_right pull-right">
                            <input value={search} onChange={onSearchChanged} className="form-control" placeholder={t("Search")} />
                        </div>
                        <div className="filter_date">
                            <div>
                                <label>Start Date</label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    placeholder={t("Start Date")}
                                    className="form-control"
                                />
                            </div>
                            <div>
                                <label>End Date</label>
                                <input
                                    type="date"
                                    value={endDate}
                                    min={startDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    placeholder={t("End Date")}
                                    className="form-control"
                                />
                            </div>
                            {startDate && (
                                <>
                                    <button onClick={() => callJobsAPI(startDate, endDate)} className="btn btn-primary">
                                        Apply
                                    </button>
                                    <button onClick={resetDates} className="btn btn-secondary">
                                        Reset
                                    </button>
                                </>
                            )}
                        </div>
                        <div className="clear"></div>
                        <div className="white_box">
                            <div className="table-responsive">

                                <table style={{ width: '100%' }} >
                                    <thead>
                                        <tr>
                                            <th>{t("job title")}</th>
                                            <th>{t("Customer name")}</th>
                                            <th>{t("Hired worker")}</th>
                                            <th>{t("Start date")}</th>
                                            <th>{t("Category")}</th>
                                            <th>{t("Job status")}</th>
                                            <th>{t("Action")}</th>
                                            <th>{t("View")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pendingJobs.length && pendingJobs[0].title ?
                                            pendingJobs.map((item, index) =>
                                                <tr>
                                                    <td>{item.title}</td>
                                                    <td>{item.user_id.name} {item.user_id.last_name}</td>
                                                    <td>{item.hiredBidder ? item.hiredBidder.name : 'Not hired yet'}</td>
                                                    {/* <td>{item.start_date.split('T')[0]}</td> */}
                                                    <td>
                                                        {moment(item.start_date).format('MM/DD/YYYY, hh:mm A')}
                                                    </td>
                                                    <td>{item.category.name}</td>
                                                    <td>{item.complete_status ? 'Completed' : item.hiredBidder ? 'In-progress' : 'Pending'}</td>
                                                    <td>{item.status == 'ACT' ?
                                                        <button onClick={() => disable(item, 'DEACT')} className="suspend_button">{t("De-activate")}</button>
                                                        :
                                                        <button onClick={() => disable(item, 'ACT')} className="active_button">{t("Activate")}</button>} </td>
                                                    <td>
                                                        <a onClick={() => goToJobDetail(item)} className="action_btn"><i className="fa fa-eye" aria-hidden="true"></i></a>
                                                    </td>
                                                </tr>
                                            )
                                            :
                                            isLoading != 1 ?
                                                <tr> <td colSpan={8}><p className="no_found text-center">{t("No data found")}</p></td></tr>
                                                : null}

                                    </tbody>

                                </table>

                            </div>
                            <Pagination
                                activePage={currentPage2}
                                itemsCountPerPage={perPage2}
                                totalItemsCount={fullData2.length}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange2}
                            />
                        </div>
                    </div>
                </div>


                <div id="paid" className="tab-pane fade">
                    <div className="tables_area">
                        <h2 className="pull-left">Hired Jobs</h2>
                        <div className="input-group search_right pull-right">
                            <input value={search2} onChange={onSearchChanged2} className="form-control" placeholder={t("Search")} />
                        </div>
                        <div className="filter_date">
                            <div>
                                <label>Start Date</label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    placeholder={t("Start Date")}
                                    className="form-control"
                                />
                            </div>
                            <div>
                                <label>End Date</label>
                                <input
                                    type="date"
                                    value={endDate}
                                    min={startDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    placeholder={t("End Date")}
                                    className="form-control"
                                />
                            </div>
                            {startDate && (
                                <>
                                    <button onClick={() => callJobsAPI(startDate, endDate)} className="btn btn-primary">
                                        Apply
                                    </button>
                                    <button onClick={resetDates} className="btn btn-secondary">
                                        Reset
                                    </button>
                                </>
                            )}
                        </div>
                        <div className="clear"></div>
                        <div className="white_box">
                            <div className="table-responsive">
                                <table style={{ width: '100%' }} >
                                    <thead>
                                        <tr>
                                            <th>{t("job title")}</th>
                                            <th>{t("Customer name")}</th>
                                            <th>{t("Hired worker")}</th>
                                            <th>{t("Start date")}</th>
                                            <th>{t("Category")}</th>
                                            <th>{t("Final Price")}</th>
                                            <th>{t("View")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {hiredJobs.length && hiredJobs[0].title ?
                                            hiredJobs.map((item, index) =>
                                                <tr>
                                                    <td>{item.title}</td>
                                                    <td>{item.user_id.name} {item.user_id.last_name}</td>
                                                    <td>{item.hiredBidder ? item.hiredBidder.name + " " + item.hiredBidder.last_name : t('Not hired yet')}</td>
                                                    {/* <td>{item.start_date.split('T')[0]}</td> */}
                                                    <td>
                                                        {moment(item.start_date).format('MM/DD/YYYY, hh:mm A')}
                                                    </td>
                                                    <td>{item.category.name}</td>
                                                    <td>CFA {Number(item.finalPrice).toLocaleString('de-DE')}</td>
                                                    <td>
                                                        <a onClick={() => goToJobDetail(item)} className="action_btn"><i className="fa fa-eye" aria-hidden="true"></i></a>
                                                    </td>

                                                </tr>
                                            )
                                            :
                                            isLoading != 1 ?
                                                <tr> <td colSpan={7}><p className="no_found text-center">{t("No data found")}</p></td></tr>
                                                : null}

                                    </tbody>

                                </table>
                            </div>
                            <Pagination
                                activePage={currentPage3}
                                itemsCountPerPage={perPage3}
                                totalItemsCount={fullData3.length}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange3}
                            />
                        </div>
                    </div>
                </div>

                <div id="completed" className="tab-pane fade">
                    <div className="tables_area">
                        <h2 className="pull-left">{t("Completed")}</h2>
                        <div className="input-group search_right pull-right">
                            <input value={search3} onChange={onSearchChanged3} className="form-control" placeholder={t("Search")} />
                        </div>
                        <div className="filter_date">
                            <div>
                                <label>Start Date</label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    placeholder={t("Start Date")}
                                    className="form-control"
                                />
                            </div>
                            <div>
                                <label>End Date</label>
                                <input
                                    type="date"
                                    value={endDate}
                                    min={startDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    placeholder={t("End Date")}
                                    className="form-control"
                                />
                            </div>
                            {startDate && (
                                <>
                                    <button onClick={() => callJobsAPI(startDate, endDate)} className="btn btn-primary">
                                        Apply
                                    </button>
                                    <button onClick={resetDates} className="btn btn-secondary">
                                        Reset
                                    </button>
                                </>
                            )}
                        </div>
                        <div className="clear"></div>
                        <div className="white_box">
                            <div className="table-responsive">
                                <table style={{ width: '100%' }} >
                                    <thead>
                                        <tr>
                                            <th>{t("job title")}</th>
                                            <th>{t("Customer name")}</th>
                                            <th>{t("Hired worker")}</th>
                                            <th>{t("Start date")}</th>
                                            <th>{t("Category")}</th>
                                            <th>{t("Final Price")}</th>
                                            <th>{t("View")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allJobs.length && allJobs[0].title ?
                                            allJobs.map((item, index) =>
                                                <tr>
                                                    <td>{item.title}</td>
                                                    <td>{item.user_id.name} {item.user_id.last_name}</td>
                                                    <td>{item.hiredBidder ? item.hiredBidder.name + " " + item.hiredBidder.last_name : t('Not hired yet')}</td>
                                                    {/* <td>{item.start_date.split('T')[0]}</td> */}
                                                    <td>
                                                        {moment(item.start_date).format('MM/DD/YYYY, hh:mm A')}
                                                    </td>
                                                    <td>{item.category.name}</td>
                                                    <td>CFA {Number(item.finalPrice).toLocaleString('de-DE')}</td>
                                                    <td>
                                                        <a onClick={() => goToJobDetail(item)} className="action_btn"><i className="fa fa-eye" aria-hidden="true"></i></a>
                                                    </td>

                                                </tr>
                                            )
                                            :
                                            isLoading != 1 ?
                                                <tr> <td colSpan={7}><p className="no_found text-center">{t("No data found")}</p></td></tr>
                                                : null}

                                    </tbody>
                                </table>
                            </div>
                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={perPage}
                                totalItemsCount={fullData.length}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>

            </div>
            <ToastContainer />

        </section>

    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(JobsManagement)