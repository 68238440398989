import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'

import { userService } from '../_services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReadMoreAndLess from 'react-read-more-less';
import { useTranslation } from "react-i18next";
import { DummyImage } from '../config/config';
import moment from 'moment';




const ProviderManagement = (props: any) => {
    const { t, i18n } = useTranslation();
    const [workersList, setworkersList] = useState([{ name: "", last_name: "", email: "", profile_image: "", birthdate: "", location: "", _id: "", status: "", certified: 0 }]);
    const [requestsList, setrequestsList] = useState([{ name: "", email: "", profile_image: "", birthdate: "", location: "" }]);
    const [rejectList, setrejectList] = useState([{ name: "", email: "", profile_image: "", birthdate: "", rejectReason: "", location: "", status: "", _id: "" }]);
    const [backupWorkerData, setbackupWorkerData] = useState([{ name: "", last_name: "", email: "", profile_image: "", birthdate: "", location: "", _id: "", status: "", certified: 0 }])
    const [backupRequestData, setRequestbackupData] = useState([{ name: "", email: "", profile_image: "", birthdate: "", location: "" }])
    const [fullData, setfullData] = useState([])
    const [perPage, setperPage] = useState(5)
    const [currentPage, setcurrentPage] = useState(1)
    const [fullData2, setfullData2] = useState([])
    const [perPage2, setperPage2] = useState(5)
    const [currentPage2, setcurrentPage2] = useState(1)
    const [fullData3, setfullData3] = useState([])
    const [perPage3, setperPage3] = useState(5)
    const [currentPage3, setcurrentPage3] = useState(1)
    const [isLoading, setisLoading] = useState(1);
    const [search, setsearch] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    // useEffect(() => callWorkersAPI(), []);
    useEffect(() => {
        callWorkersAPI(startDate, endDate)
    }, [])

    const dateFormatter = (cell, row) => {
        return moment(cell).format('MM/DD/YYYY, hh:mm A');
    };




    const columns = [{
        dataField: 'photo',
        text: t('Photo'),
        formatter: photoFormatter
    }, {
        dataField: 'name',
        text: t('First Name'),
        sort: true
    },
    {
        dataField: 'last_name',
        text: t('Last Name'),
        sort: true
    }, {
        dataField: 'email',
        text: t('Email'),
        sort: true
    },
    // {
    //     dataField: 'location',
    //     text: 'Address',
    //     sort: true,
    //     formatter: locationFormatter,
    //     sortValue: (cell, row) => row.location
    // },
    {
        dataField: 'createdAt',
        text: t('Joining Date'),
        sort: true,
        formatter: (cell, row) => (
            <>
                {moment(cell).format('MM/DD/YYYY, hh:mm A')}
                </>
        ),
        classes: 'white-space-nowrap'
    }, {
        dataField: 'jobCompleted',
        text: t('Jobs Done'),
        sort: true
    }, {
        dataField: 'jobs',
        text: t('Jobs'),
        formatter: jobsFormatter,
    }, {
        dataField: 'certified',
        text: t('Certify'),
        sort: true,
        formatter: certifyFormatter,
        sortValue: (cell, row) => row.certified
    }, {
        dataField: 'account_status',
        text: t('Account Status'),
        formatter: statusFormatter,
    }, {
        dataField: 'action',
        text: t('Action'),
        sort: true,
        formatter: actionFormatter,
        sortValue: (cell, row) => row.status
    }, {
        dataField: 'view',
        text: t('View'),
        formatter: viewFormatter
    }];


    const columnsRequests = [{
        dataField: 'photo',
        text: t('Photo'),
        formatter: photoFormatter
    }, {
        dataField: 'name',
        text: t('First Name'),
        sort: true
    }, {
        dataField: 'last_name',
        text: t('Last Name'),
        sort: true
    }, {
        dataField: 'email',
        text: t('Email'),
        sort: true
    }, {
        dataField: 'location',
        text: t('Address'),
        sort: true,
        formatter: locationFormatter,
        sortValue: (cell, row) => row.location
    }, {
        dataField: 'createdAt',
        text: t('Joining Date'),
        sort: true,
        formatter: (cell, row) => (
            <>
                {moment(cell).format('MM/DD/YYYY, hh:mm A')}
                </>
        ),
        classes: 'white-space-nowrap'
        
    }, {
        dataField: 'view',
        text: t('View'),
        formatter: viewFormatterRequest
    }];

    const columnsRejected = [{
        dataField: 'photo',
        text: t('Photo'),
        formatter: photoFormatter
    }, {
        dataField: 'name',
        text: t('First Name'),
        sort: true
    }, {
        dataField: 'last_name',
        text: t('Last Name'),
        sort: true
    }, {
        dataField: 'email',
        text: t('Email'),
        sort: true
    }, {
        dataField: 'location',
        text: t('Address'),
        sort: true,
        formatter: locationFormatter,
        sortValue: (cell, row) => row.location
    }, {
        dataField: 'createdAt',
        text: t('Joining Date'),
        sort: true,
        formatter: (cell, row) => (
            <>
                {moment(cell).format('MM/DD/YYYY, hh:mm A')}
                </>
        ),
        classes: 'white-space-nowrap'
    }, {
        dataField: 'rejectReason',
        text: t('Reason of rejection'),
        sort: true
    }, {
        dataField: 'action',
        text: t('Action'),
        formatter: viewFormatterReject
    }];

    const resetDates = () => {
        callWorkersAPI("", "");
       
        setStartDate('');
        setEndDate('');
    };

    function photoFormatter(cellContent, row, rowIndex) {
        return (
            <>
                <img
                    src={row.profile_image}
                    alt=""
                    onError={(event) => ((event.target as HTMLImageElement).src = DummyImage)}

                    className="img-circle user_image"
                />

            </>
        );
    }
    function jobsFormatter(cellContent, row, rowIndex) {
        return (
            <>
                <a onClick={() => goToJobs(row)} className="view_jobs">{t("View jobs")}</a>
            </>
        );
    }

    function actionFormatter(cellContent, row, rowIndex) {
        return (
            <>
                {row.status == 'ACT' ?
                    <button onClick={() => suspendUser(row._id, 'REJ')} className="suspend_button">{t("Suspend")}</button>
                    :
                    <button onClick={() => suspendUser(row._id, 'ACT')} className="active_button">{t("Activate")}</button>
                }
            </>
        );
    }

    function certifyFormatter(cellContent, row, rowIndex) {
        return (
            <>
                {row.certified == 1 ?
                    <button onClick={() => certifyUser(row._id, 0)} className="active_button">{t("Certified")}</button>
                    :
                    <button onClick={() => certifyUser(row._id, 1)} className="suspend_button">{t("Certify")}</button>
                }
            </>
        );
    }

    function viewFormatter(cellContent, row, rowIndex) {
        return (
            <>
                <a onClick={() => goToProfile(row)} className="action_btn"><i className="fa fa-eye" aria-hidden="true"></i></a>

            </>
        );
    }
    function locationFormatter(cellContent, row, rowIndex) {
        return (
            <>
                <ReadMoreAndLess
                    className="read-more-content"
                    charLimit={50}
                    readMoreText="Read more"
                    readLessText="Read less"
                >
                    {row.commune}{row.commune ? "," : null} {row.appartment}{row.appartment ? "," : null} {row.location}, {row.city}, {row.state}, {row.country}{row.zip_code ? "," : null} {row.zip_code}
                </ReadMoreAndLess>
            </>
        );
    }


    function viewFormatterRequest(cellContent, row, rowIndex) {
        return (
            <>
                <a onClick={() => goToRequestProfile(row)} className="action_btn"><i className="fa fa-eye" aria-hidden="true"></i></a>
            </>
        );
    }

    function viewFormatterReject(cellContent, row, rowIndex) {
        return (
            <>
                <button onClick={() => approveRej(row._id, 'ACT')} className="suspend_button">{t("Approve")}</button>
            </>
        );
    }

    function statusFormatter(cellContent, row, rowIndex) {
        return (
            <>
                {cellContent == "A" ?
                    <span style={{ color: 'green' }}>{t("Active")}</span>
                    :
                    <span style={{ color: 'red' }}>{t("Deleted")}</span>
                }
            </>
        );
    }


    function callWorkersAPI(startDate: string, endDate: string) {
        const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
        const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';
        const params = { startDate: startDate, endDate: endDate };
        if (formattedStartDate) params.startDate = formattedStartDate;
        if (formattedEndDate) params.endDate = formattedEndDate;
        userService.workers(params)
            .then(response => {
                console.log(response.data)
                var data = response.data.workers;
                var newdata = [] as any;
                for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                    if (data[i]) {
                        newdata.push(data[i]);
                    }
                }
                // setworkersList(newdata)
                setworkersList(data)
                setfullData(data);
                setbackupWorkerData(data)

                var data2 = response.data.requests;
                var newdata2 = [] as any;
                for (var i = (0 * perPage2); i < ((0 * perPage2) + perPage2); i++) {
                    if (data2[i]) {
                        newdata2.push(data2[i]);
                    }
                }
                console.log("newdata2", newdata2)
                // setrequestsList(newdata2)
                setrequestsList(data2)
                setfullData2(data2);
                setRequestbackupData(data2)

                var data3 = response.data.rejected;
                var newdata3 = [] as any;
                for (var i = (0 * perPage3); i < ((0 * perPage3) + perPage3); i++) {
                    if (data3[i]) {
                        newdata3.push(data3[i]);
                    }
                }
                console.log("newdata2", newdata3)
                // setrejectList(newdata3)
                setrejectList(data3)
                setfullData3(data3);


                setcurrentPage(1)
                setisLoading(2)
            })
            .catch(error => {
                console.log(error.response)
            });
    }


    function approveRej(id, status) {
        let result;
        if (status == "ACT") {
            result = window.confirm('Are you sure you want to approve this user?')
        }

        if (result && status == "ACT") {
            var params = {
                user_id: id,
                status: status,
                rejectReason: ''
            }
            userService.changeStatus(params)
                .then(response => {
                    toast.success("User approved successfully")
                    console.log("response.user", response)
                    callWorkersAPI(startDate, endDate);
                })
                .catch(error => {
                    console.log(error.response)
                });
        }
    }

    function handlePageChange(pageNumber) {
        console.log(pageNumber)
        var newdata = [] as any;
        for (var i = ((pageNumber - 1) * perPage); i < (((pageNumber - 1) * perPage) + perPage); i++) {
            if (fullData[i]) {
                console.log(fullData[i])
                newdata.push(fullData[i]);
            }
        }
        setcurrentPage(pageNumber)
        setworkersList(newdata)
    }


    function handlePageChange2(pageNumber) {
        console.log(pageNumber)
        var newdata = [] as any;
        for (var i = ((pageNumber - 1) * perPage); i < (((pageNumber - 1) * perPage) + perPage); i++) {
            if (fullData2[i]) {
                console.log(fullData2[i])
                newdata.push(fullData2[i]);
            }
        }
        setcurrentPage2(pageNumber)
        setworkersList(newdata)
    }

    function handlePageChange3(pageNumber) {
        console.log(pageNumber)
        var newdata = [] as any;
        for (var i = ((pageNumber - 1) * perPage); i < (((pageNumber - 1) * perPage) + perPage); i++) {
            if (fullData3[i]) {
                console.log(fullData3[i])
                newdata.push(fullData3[i]);
            }
        }
        setcurrentPage3(pageNumber)
        setrejectList(newdata)
    }


    function certifyUser(id, status) {
        let result;
        if (status == 1) {
            result = window.confirm(t("Are you sure you want to certify this worker's account?"))
        }
        else {
            result = window.confirm(t("Are you sure you want to un-certify this worker's account?"))
        }

        if (result) {
            setisLoading(1)
            var params = {
                user_id: id,
                certified: status
            }
            userService.changeCertification(params)
                .then(response => {
                    console.log("response.user", response)
                    setisLoading(2)
                    setTimeout(
                        () => {
                            toast.success(status == 1 ? t("Certified successfully") : t("Un-certified Successfully"))
                        },
                        500
                    );
                    callWorkersAPI(startDate, endDate);

                })
                .catch(error => {
                    console.log(error.response)
                });
        }
    }

    function suspendUser(id, status) {
        let result;
        if (status == "REJ") {
            result = window.confirm(t("Are you sure you want to suspend this worker's account?"))
        }
        else {
            result = window.confirm(t("Are you sure you want to activate this worker's account?"))
        }

        if (result) {
            setisLoading(1)
            var params = {
                user_id: id,
                status: status
            }
            userService.changeStatus(params)
                .then(response => {
                    console.log("response.user", response)
                    setisLoading(2)
                    setTimeout(
                        () => {
                            toast.success(status == "SUS" ? t("Suspended successfully") : t("Activated Successfully"))
                        },
                        500
                    );
                    callWorkersAPI(startDate, endDate);

                })
                .catch(error => {
                    console.log(error.response)
                });
        }
    }
    function onSearchChanged(event) {
        setsearch(search.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        if (search.length) {
            var searchData = [] as any;
            for (var j in backupWorkerData) {
                var fullname = backupWorkerData[j].name + " " + backupWorkerData[j].last_name;
                console.log("fullname-----", fullname)
                if (backupWorkerData[j].name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupWorkerData[j].last_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    fullname.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupWorkerData[j].email.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupWorkerData[j].location.toLowerCase().includes(event.target.value.toLowerCase())) {
                    searchData.push(backupWorkerData[j]);
                }
            }
            var data = searchData;
            var newdata = [] as any;
            for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                if (data[i]) {
                    newdata.push(data[i]);
                }
            }
            setworkersList(newdata)
            setfullData(data);
            setcurrentPage(1)
        }
    }



    function goToProfile(item) {
        window.location.href = '/userprofile/P/' + item._id;
    }
    function goToRequestProfile(item) {
        window.location.href = '/requestProfile/' + item._id;
    }
    function goToJobs(item) {
        window.location.href = '/jobs/' + item._id + '/worker/' + item.name;
    }

    const { error, handleSubmit, pristine, reset, submitting } = props
    return (
        <section className="my_job_tab_box">
            {isLoading == 1 &&
                <div className="loader-back">
                    <div className="loaderrrr">
                        <span>
                            <img src={require("../assets/images/loader.gif")} alt="" />
                        </span>

                    </div>
                </div>
            }
            <div className="tab_heading_content">
                <ul className="nav nav-tabs">
                    <li className="active active_list"><a data-toggle="tab" href="#active">{t("Workers")}</a></li>
                    <li className="upcoming_list"><a data-toggle="tab" href="#requests">{t("Requests")}</a></li>
                    <li className="past-list"><a data-toggle="tab" href="#rejected">{t("Rejected")}</a></li>

                </ul>
            </div>
            <div className="tab-content">
                <div id="active" className="tab-pane fade in active">
                    <div className="tables_area">
                        <h2 className="pull-left">{t("Providers")}</h2>
                        <div className="input-group search_right pull-right">
                            <input value={search} onChange={onSearchChanged} className="form-control" placeholder={t("Search")} />

                        </div>
                        <div className="filter_date">
                            <div>
                                <label>Start Date</label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    placeholder={t("Start Date")}
                                    className="form-control"
                                />
                            </div>
                            <div>
                                <label>End Date</label>
                                <input
                                    type="date"
                                    value={endDate}
                                    min={startDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    placeholder={t("End Date")}
                                    className="form-control"
                                />
                            </div>
                            {startDate && (
                                <>
                                    <button onClick={() =>  callWorkersAPI(startDate, endDate)} className="btn btn-primary">
                                        Apply
                                    </button>
                                    <button onClick={resetDates} className="btn btn-secondary">
                                        Reset
                                    </button>
                                </>
                            )}
                        </div>
                        <div className="clear"></div>
                        <div className="white_box">
                            <div className="table-responsive inner-main-table">
                                <div className="pagination_box">
                                    <BootstrapTable keyField='id' data={workersList} columns={columns} pagination={paginationFactory({ sizePerPage: 5 })} />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>


                <div id="requests" className="tab-pane fade">
                    <div className="tables_area">
                        <h2 className="pull-left">{t("Requests")}</h2>
                        <div className="input-group search_right pull-right">
                            <input value={search} onChange={onSearchChanged} className="form-control" placeholder={t("Search")} />

                        </div>
                        <div className="filter_date">
                            <div>
                                <label>Start Date</label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    placeholder={t("Start Date")}
                                    className="form-control"
                                />
                            </div>
                            <div>
                                <label>End Date</label>
                                <input
                                    type="date"
                                    value={endDate}
                                    min={startDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    placeholder={t("End Date")}
                                    className="form-control"
                                />
                            </div>
                            {startDate && (
                                <>
                                    <button onClick={() => callWorkersAPI(startDate, endDate)} className="btn btn-primary">
                                        Apply
                                    </button>
                                    <button onClick={resetDates} className="btn btn-secondary">
                                        Reset
                                    </button>
                                </>
                            )}
                        </div>
                        <div className="clear"></div>
                        <div className="white_box">
                            <div className="table-responsive inner-main-table">
                                <div className="pagination_box ">
                                    <BootstrapTable keyField='id' data={requestsList} columns={columnsRequests} pagination={paginationFactory({ sizePerPage: 5 })} />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>


                <div id="rejected" className="tab-pane fade">
                    <div className="tables_area">
                        <h2 className="pull-left">{t("Rejected")}</h2>
                        <div className="input-group search_right pull-right">
                            <input value={search} onChange={onSearchChanged} className="form-control" placeholder={t("Search")} />

                        </div>
                        <div className="filter_date">
                            <div>
                                <label>Start Date</label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    placeholder={t("Start Date")}
                                    className="form-control"
                                />
                            </div>
                            <div>
                                <label>End Date</label>
                                <input
                                    type="date"
                                    min={startDate}
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    placeholder={t("End Date")}
                                    className="form-control"
                                />
                            </div>
                            {startDate && (
                                <>
                                    <button onClick={() => callWorkersAPI(startDate, endDate)} className="btn btn-primary">
                                        Apply
                                    </button>
                                    <button onClick={resetDates} className="btn btn-secondary">
                                        Reset
                                    </button>
                                </>
                            )}
                        </div>
                        <div className="clear"></div>
                        <div className="white_box">
                            <div className="table-responsive inner-main-table">
                                <div className="pagination_box">
                                    <BootstrapTable keyField='id' data={rejectList} columns={columnsRejected} pagination={paginationFactory({ sizePerPage: 5 })} />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <ToastContainer />

        </section>

    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(ProviderManagement)