import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
// Importing Images
import profile_image from '../assets/images/profile_image.jpg';
import { userService } from '../_services';
import Pagination from "react-js-pagination";
import ReadMoreAndLess from 'react-read-more-less';
import { useTranslation } from "react-i18next";
import moment from 'moment';



const Rating = (props: any) => {
    const { t, i18n } = useTranslation();
    const [jobsList, setjobsList] = useState([{ createdAt: "", _id: "", title: "", user_id: { name: "", last_name: "" }, hiredBidder: { name: "", last_name: "" }, category: { name: "" }, worker_review: { rating: "", message: "" }, customer_review: { rating: "", message: "" } }]);
    const [backupData, setbackupData] = useState([{ _id: "", title: "", user_id: { name: "", last_name: "" }, hiredBidder: { name: "", last_name: "" }, category: { name: "" }, worker_review: { rating: "", message: "" }, customer_review: { rating: "", message: "" } }])
    const [fullData, setfullData] = useState([])
    const [perPage, setperPage] = useState(10)
    const [currentPage, setcurrentPage] = useState(1)
    const [isLoading, setisLoading] = useState(1);
    const [search, setsearch] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => callWorkerJobsAPI(startDate, endDate), []);


    function callWorkerJobsAPI(startDate: string, endDate: string) {
        const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
        const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';
        const params = { startDate: startDate, endDate: endDate };
        userService.ratings(params)
            .then(response => {
                console.log(response)
                var data = response.data.jobs;
                var newdata = [] as any;
                for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                    if (data[i]) {
                        console.log(data[i])
                        newdata.push(data[i]);
                    }
                }
                setjobsList(newdata)
                setfullData(data);
                setbackupData(data)
                setisLoading(2)
            })
            .catch(error => {
                console.log(error.response)
            });
    }

    const resetDates = () => {
        callWorkerJobsAPI("", "");

        setStartDate('');
        setEndDate('');
    };


    function handlePageChange(pageNumber) {
        console.log(pageNumber)
        var newdata = [] as any;
        for (var i = ((pageNumber - 1) * perPage); i < (((pageNumber - 1) * perPage) + perPage); i++) {
            if (fullData[i]) {
                console.log(fullData[i])
                newdata.push(fullData[i]);
            }
        }
        setcurrentPage(pageNumber)
        setjobsList(newdata)
    }

    function onSearchChanged(event) {
        setsearch(search.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        if (search.length) {
            var searchData = [] as any;
            for (var j in backupData) {
                var fullnameCus = backupData[j].user_id.name + " " + backupData[j].user_id.last_name;
                var fullnameWorker = backupData[j].hiredBidder.name + " " + backupData[j].hiredBidder.last_name;
                if (backupData[j].title.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupData[j].user_id.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupData[j].user_id.last_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    fullnameCus.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupData[j].hiredBidder.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupData[j].hiredBidder.last_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    fullnameWorker.toLowerCase().includes(event.target.value.toLowerCase())
                ) {
                    searchData.push(backupData[j]);
                }
            }
            var data = searchData;
            var newdata = [] as any;
            for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                if (data[i]) {
                    console.log(data[i])
                    newdata.push(data[i]);
                }
            }
            setjobsList(newdata)
            setfullData(data);
            setcurrentPage(1)
        }
    }

    const { error, handleSubmit, pristine, reset, submitting } = props
    return (
        <div className="tables_area">
            {isLoading == 1 &&
                <div className="loader-back">
                    <div className="loaderrrr">
                        <span>
                            <img src={require("../assets/images/loader.gif")} alt="" />
                        </span>

                    </div>
                </div>
            }
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h2 className="pull-left">{t("Rating & Review Management")}</h2>

                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <div className="filter_date">
                        <div>
                            <label>Start Date</label>
                            <input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                placeholder={t("Start Date")}
                                className="form-control"
                            />
                        </div>
                        <div>
                            <label>End Date</label>
                            <input
                                type="date"
                                value={endDate}
                                min={startDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                placeholder={t("End Date")}
                                className="form-control"
                            />
                        </div>
                        {startDate && (
                            <>
                                <button onClick={() => { callWorkerJobsAPI(startDate, endDate) }} className="btn btn-primary">
                                    Apply
                                </button>
                                <button onClick={resetDates} className="btn btn-secondary">
                                    Reset
                                </button>
                            </>
                        )}
                    </div>

                    <div className="input-group search_right pull-right">
                        <input value={search} onChange={onSearchChanged} className="form-control" placeholder={t("Search")} />
                    </div>
                </div>
            </div>

            <div className="clear"></div>
            <div className="white_box">
                <div className="table-responsive">

                    <table style={{ width: '100%' }} >
                        <thead>
                            <tr>
                                <th>{t("Job Title")}</th>
                                <th>{t("Customer name")}</th>
                                <th>{t("Customer Rating")}</th>
                                <th>{t("Customer Review")}</th>
                                <th>{t("Hired worker")}</th>
                                <th>{t("Worker Rating")}</th>
                                <th>{t("Worker Review")}</th>
                                <th>{t("Review Date")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {jobsList.length && jobsList[0].title ?
                                jobsList.map((item, index) =>
                                    <tr>
                                        <td>{item.title}</td>
                                        <td>{item.user_id.name} {item.user_id.last_name}</td>
                                        <td>{item.customer_review && item.customer_review.rating ? item.customer_review.rating : t('Not reviewed yet')}</td>
                                        <td>{item.customer_review && item.customer_review.message ?
                                            <ReadMoreAndLess
                                                className="read-more-content"
                                                charLimit={100}
                                                readMoreText={t("Read more")}
                                                readLessText={t("Read less")}
                                            >
                                                {item.customer_review.message}
                                            </ReadMoreAndLess> : t('Not reviewed yet')}</td>
                                        <td>{item.hiredBidder ? item.hiredBidder.name + " " + item.hiredBidder.last_name : t('Not hired yet')}</td>
                                        <td>{item.worker_review && item.worker_review.rating ? item.worker_review.rating : t('Not reviewed yet')}</td>
                                        <td>{item.worker_review && item.worker_review.message ?

                                            <ReadMoreAndLess
                                                className="read-more-content"
                                                charLimit={100}
                                                readMoreText={t("Read more")}
                                                readLessText={t("Read less")}
                                            >
                                                {item.worker_review.message}
                                            </ReadMoreAndLess>
                                            : t('Not reviewed yet')}</td>
                                        <td className='white-space-nowrap'>
                                            {new Date(item?.createdAt).toLocaleString('en-US', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true
                                            })}
                                        </td>

                                    </tr>
                                )
                                :
                                isLoading != 1 ?
                                    <tr> <td colSpan={7}><p className="no_found text-center">{t("No data found")}</p></td></tr>
                                    : null}

                        </tbody>
                    </table>

                    <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={perPage}
                        totalItemsCount={fullData.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(Rating)