import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { useTranslation } from "react-i18next";

import { userService } from '../_services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const EditAdmin = (props: any) => {
    const { t, i18n } = useTranslation();

    const [checked, setchecked] = useState(true);
    const [userprofile, setuserprofile] = useState({ name: "", email: "", profile_image: "", birthdate: "", location: "", bio: "", categories: "", subcategories: "", profile_photo: "" });
    const [adminPhoto, setadminPhoto] = useState('');
    const [userName, setuserName] = useState('');
    const [uploadImage, setuploadImage] = useState('');
    const [showImage, setshowImage] = useState('');
    const [userid, setUserid] = useState('');

    function handleChange() {
        setchecked(!checked)
    }


    useEffect(() => callAdminProfileAPI(), []);



    function callAdminProfileAPI() {
        userService.adminProfile()
            .then(response => {
                console.log(response.data)
                setuserprofile(response.data.admin)
                setadminPhoto(response.data.admin.profile_photo)
                setuserName(response.data.admin.name)
            })
            .catch(error => {
                console.log(error.response)
            });
    }

    function updateProfile() {
        const formData = new FormData();
        formData.append('profile_photo', uploadImage);
        formData.append('name', userName);
        console.log("formData",uploadImage)
        userService.adminProfileUpdate(formData)
            .then(response => {
                console.log("response.user", response)
                toast.success("Profile updated successfuly!!")
                callAdminProfileAPI();
                window.location.href='/'
            })
            .catch(error => {
                console.log(error.response)
            });
    }

    function onPrfileimageUpload(event) {
        event.preventDefault();
        console.log(event.target.files[0])
        var file = event.target.files[0];
        setuploadImage(file);
        setshowImage(URL.createObjectURL(event.target.files[0]));

    }

    function onNameChnaged(event) {
        setuserName(userName.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value);
    }

    const { error, handleSubmit, pristine, reset, submitting } = props
    return (
        <div>
            <h2>{t("Edit Profile")}</h2>
            <div className="white_box my_profile">
                <div className="row">
                    <aside className="col-lg-4 text-center">
                        <div className="upload_image">
                            <img src={showImage ? showImage : adminPhoto} alt="" className="img-circle profile_image" />
                            <input type="file" onChange={onPrfileimageUpload} />
                        </div>
                        <p className="upload_text">{t("Upload Image")}</p>
                    </aside>
                    <aside className="col-lg-8">
                        <div className="row">
                            <aside className="col-sm-6">
                                <div className="user_name">
                                <label>{t("Name")}</label>
                                <input value={userName} onChange={onNameChnaged} className="form-control" type="text" />
                                </div>
                            </aside>

                        </div>

                        <button onClick={() => updateProfile()} type="submit" className="blue_btn yellow_btn text-uppercase">{t("Update")}</button>
                    </aside>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(EditAdmin)