
import React, { useEffect, useState } from 'react'

import { config } from '../../config/config'
import logo from '../../assets/images/logo.png';
import { userService } from '../../_services';
import { useTranslation } from "react-i18next";

const Header = (props: any) => {
    const { t, i18n } = useTranslation();
    const [openClass, setOpenClass] = useState(false);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [logoutpopup, setLogoutpopup] = useState(false);
    const [ language, setLanguage ] = useState("ENGLISH");
    const [profile, setprofile] = useState('');
    
    useEffect(() => {
    
        userService.adminProfile()
            .then(response => {
                console.log("headerrr",response.data)
                setprofile(response.data.admin.profile_photo)
            })
            .catch(error => {
                console.log(error.response)
            });

        if (localStorage.getItem("selectLanguage")) {
            var lang = localStorage.getItem("selectLanguage") || "ENGLISH";
            setLanguage(lang)
            localStorage.setItem("selectLanguage", lang);
            i18n.changeLanguage(lang);
        } else {
            localStorage.setItem("selectLanguage", "ENGLISH");
            i18n.changeLanguage("ENGLISH");
        }

    }, [localStorage.getItem("selectLanguage")]);

  

    const currentRoute = window.location.pathname;
    function logout(){
        var r = window.confirm("Are you sure you want to logout?");
        if (r == true) {
            localStorage.clear();
            window.location.href = `${config.appurl}`;
        } else {
           
        }  
    }


    const selectLanguage = (language) => {
        i18n.changeLanguage(language);
        setLanguage(language)
        localStorage.setItem("selectLanguage", language);   
    };

 

    const { error, className, handleSubmit, pristine, reset, submitting } = props
    return (
        <header className="header">
		<div className="dashboard_logoarea text-center">
			<a href="#" className="menu_bar"><i className="fa fa-bars" aria-hidden="true"></i></a>
			<a href="#"><img src={logo} alt="" /></a>
		</div>
		<div className="right_loggedarea">
			<ul>
                <li className={'language-select-area'}>
                    <select onChange={(e) => selectLanguage(e.target.value)} value={language}>
                            <option value={"ENGLISH"} style={{ cursor: "pointer" }}>
                                English
                            </option>
                            <option value={"FRENCH"} style={{ cursor: "pointer" }}>
                                French
                            </option>    
                    </select>
                    {/* <i className="fa fa-angle-down" aria-hidden="true"></i> */}
                </li>
				
				<li className={notificationOpen?"dropdown open": "dropdown"}>
					<a onClick={()=>{console.log("clieckeddd notification",notificationOpen); setNotificationOpen(!notificationOpen)}} className="dropdown-toggle" role="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{cursor:'pointer'}}><img src={profile} alt="" />{t("My Account")}
						<i className="fa fa-sort-desc" aria-hidden="true"></i>
					</a>
					<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
					    <a className="dropdown-item" href="/admin"><i className="fa fa-user" aria-hidden="true"></i>{t("My Profile")}</a>
					    <a className="dropdown-item" href="/editadmin"><i className="fa fa-edit" aria-hidden="true"></i>{t("Edit Profile")}</a>
                        <a className="dropdown-item" href="/changepassword"><i className="fa fa-key" aria-hidden="true"></i>{t("Change password")}</a>
					    <a className="dropdown-item" onClick={logout}><i className="fa fa-power-off" aria-hidden="true"></i>{t("Logout")}</a>
					</div>
				</li>
			</ul>
		</div>
	</header>


    )
}

export default Header

//  reduxForm({
//    form: 'loginForm' // a unique identifier for this form
//  })(LoginHeader)