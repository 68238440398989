import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { userService } from '../_services';
import Pagination from "react-js-pagination";
import { config } from '../config/config'
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";

const customStyles1 = {
    overlay: {
        display: "block",
        paddingRight: "15px",
        backgroundColor: 'rgba(51,51,51,0.8)',
        zIndex: 99
    },

    content: {
        position: "",
        top: "",
        right: "",
        bottom: "",
        left: ""
    }
};

const Category = (props: any) => {
    const { t, i18n } = useTranslation();
    const [categoryList, setCategoryList] = useState([{ _id: "", name: "",description:"", image: "", selectedImage:"", service_professionals_count:0 , status:""}]);
    const [backupData, setbackupData] = useState([{ _id: "", name: "",description:"", image: "", selectedImage:"", service_professionals_count:0 , status:"" }])
    const [fullData, setfullData] = useState([])
    const [perPage, setperPage] = useState(10)
    const [currentPage, setcurrentPage] = useState(1)
    const [isLoading, setisLoading] = useState(1);
    const [search, setsearch] = useState('');
    const [editModal, seteditModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({_id:""});
    const [catName, setCatName] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState("");
    const [selectedImage, setSelectedImage] = useState("");
    const [uploadImage, setuploadImage] = useState("");
    const [uploadselectedImage, setUploadSelectedImage] = useState("");
    const [language, setLanguage] = useState(localStorage.getItem("selectLanguage")=="ENGLISH" ? "en": "fr");
    

    useEffect(() => getCategoryList(language), []);

    useEffect(() => {
        var lang = localStorage.getItem("selectLanguage")=="ENGLISH" ? "en": "fr"
        setLanguage(lang)
        getCategoryList(lang)
    }, [localStorage.getItem("selectLanguage")]);


    function getCategoryList(language) {
        setLanguage(language)
        userService.categories(language)
            .then(response => {
                console.log(response)
                var data = response.data.data;
                var newdata = [] as any;
                for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                    if (data[i]) {
                        console.log(data[i])
                        newdata.push(data[i]);
                    }
                }
                setCategoryList(newdata)
                setfullData(data);
                setbackupData(data)
                setisLoading(2)
            })
            .catch(error => {
                console.log(error.response)
            });
    }




    function handlePageChange(pageNumber) {
        console.log(pageNumber)
        var newdata = [] as any;
        for (var i = ((pageNumber - 1) * perPage); i < (((pageNumber - 1) * perPage) + perPage); i++) {
            if (fullData[i]) {
                console.log(fullData[i])
                newdata.push(fullData[i]);
            }
        }
        setcurrentPage(pageNumber)
        setCategoryList(newdata)
    }

    function onSearchChanged(event) {
        setsearch(search.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        if (search.length) {
            var searchData = [] as any;
            for (var j in backupData) {
                if (backupData[j].name.toLowerCase().includes(event.target.value.toLowerCase())) {
                    searchData.push(backupData[j]);
                }
            }
            var data = searchData;
            var newdata = [] as any;
            for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                if (data[i]) {
                    console.log(data[i])
                    newdata.push(data[i]);
                }
            }
            setCategoryList(newdata)
            setfullData(data);
            setcurrentPage(1)
        }
    }

    function editNewCategory(item) {
        setSelectedCategory(item)
        setCatName(item.name)
        setDescription(item.description)
        setImage(item.image)
        setSelectedImage(item.selectedImage)
        setuploadImage("")
        setUploadSelectedImage("")
        seteditModal(true)
    }

    function addNewCategory() {
        setSelectedCategory({_id:""})
        setCatName("")
        setDescription("")
        setImage("")
        setSelectedImage("")
        setuploadImage("")
        setUploadSelectedImage("")
        seteditModal(true)
    }


    function goToSubcategories(item) {
        window.location.href = '/categories/' + item._id + '/' + item.name;
    }

    function closeModal() {
        seteditModal(false)
    }

    function onNameChanged(event) {
        setCatName(catName && catName.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }
    function onDescriptionChanged(event) {
        setDescription(description && description.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    function updateCategory() {
        if (!catName) {
            toast.error(t("Please enter category name."))
        }
        else if (!description) {
            toast.error(t("Please enter category description."))
        }
        else {
            const formData = new FormData();
            formData.append('name', catName);
            formData.append('description', description);
            if(uploadImage){
                formData.append('image', uploadImage); 
            }
            if(uploadselectedImage){
                formData.append('selectedImage', uploadselectedImage);
            }
            console.log("formData",uploadImage)

            userService.updateCategory(formData, selectedCategory._id, language)
                .then(response => {
                    console.log("response", response)
                    getCategoryList(language);
                    toast.success(t("Category updated successfully."))
                    seteditModal(false)
                })
                .catch(error => {
                    console.log(error.response)
                });
        }
    }

    function addCategory() {
        if (!uploadImage) {
            toast.error(t("Please select image."))
        }
        else if (!uploadselectedImage) {
            toast.error(t("Please select selected image."))
        }
        else if (!catName) {
            toast.error(t("Please enter category name."))
        }
        else if (!description) {
            toast.error(t("Please enter category description."))
        }
        else {
            const formData = new FormData();
            formData.append('name', catName);
            formData.append('description', description);
            formData.append('image', uploadImage); 
            formData.append('selectedImage', uploadselectedImage);

            userService.addCategory(formData, language)
                .then(response => {
                    console.log("response", response)
                    getCategoryList(language);
                    toast.success(t("Category added successfully."))
                    seteditModal(false)
                })
                .catch(error => {
                    console.log(error.response)
                });
        }
    }

    function onImageChanged(event) {
        event.preventDefault();
        var file = event.target.files[0];
        setuploadImage(file);
        setImage(URL.createObjectURL(event.target.files[0]));
    }

    function onSelectedImageChanged(event) {
        event.preventDefault();
        var file = event.target.files[0];
        setUploadSelectedImage(file);
        setSelectedImage(URL.createObjectURL(event.target.files[0]));
    }


    return (
        <div className="tables_area">
            {isLoading == 1 &&
                <div className="loader-back">
                    <div className="loaderrrr">
                        <span>
                            <img src={require("../assets/images/loader.gif")} alt="" />
                        </span>

                    </div>
                </div>
            }
            <h2 className="pull-left">{t("Category Management")}</h2>
            <div className="input-group search_right pull-right category-search-top-right">
                    {/* <select
                        className="form-select"
                        onChange={(e) => getCategoryList(e.target.value)}
                        value={language}
                    >
                        <option value="en">English</option>
                        <option value="fr">French</option>
                    </select> */}
                <input value={search} onChange={onSearchChanged} className="form-control" placeholder={t("Search")} />
                <button onClick={() => addNewCategory()} className="active_button">{t("Add Category")}</button>
            </div>
            <div className="clear"></div>
            <div className="white_box">
                <div className="table-responsive">

                    <table style={{ width: '100%' }} >
                        <thead>
                            <tr>
                                <th>{t("Photo")}</th>
                                <th>{t("Selected Photo")}</th>
                                <th>{t("Name")}</th>
                                <th>{t("Description")}</th>
                                <th>{t("Subcategories")}</th>
                                <th>{t("Action")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categoryList.length && categoryList[0].name ?
                                categoryList.map((item, index) =>
                                    <tr>
                                        <td> <img src={item.image} alt="" className="img-circle user_image" /></td>
                                        <td><img src={item.selectedImage} alt="" className="img-circle user_image" /></td>
                                        <td>{item.name}</td>
                                        <td>{item.description}</td>
                                        <td><a onClick={() => goToSubcategories(item)} className="action_btn"><i className="fa fa-eye" aria-hidden="true"></i></a></td>
                                        <td><a onClick={() => editNewCategory(item)} className="view_jobs">{t("Edit")}</a></td>
                                    </tr>
                                )
                                :
                                isLoading != 1 ?
                                <tr> <td colSpan={5}><p className="no_found text-center">{t("No data found")}</p></td></tr>
                                    : null}

                        </tbody>
                    </table>

                    <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={perPage}
                        totalItemsCount={fullData.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                </div>
            </div>

            <Modal
                ariaHideApp={false}
                isOpen={editModal}
                onRequestClose={closeModal}
                className={
                    "modal-content  new_modal_content add_profile_modal-content category-modal"}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title">{t("Add Category")}<span><i className="fa fa-times-circle-o" onClick={closeModal} aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                </div>
                <div className="modal-body">

                    <div className="row">
                        <aside className="col-sm-12">
                            <div className='upload-area'>
                            <div className="upload_image catory-images">
                                <h5>{t("Photo")}</h5>
                            <img src={image ? image : require("../assets/images/upload-image.png")} alt="" className="img-circle profile_image" />
                            <input type="file" onChange={onImageChanged} />
                        </div>
                            <div className="upload_image catory-images">
                            <h5>{t("Selected Photo")}</h5>
                            <img src={selectedImage ? selectedImage : require("../assets/images/upload-image.png")} alt="" className="img-circle profile_image" />
                            <input type="file" onChange={onSelectedImageChanged} />
                        </div>
                            </div>
                       
                            <div className="form-group">
                                <input value={catName} onChange={onNameChanged} className="form-control" placeholder={t("Name")} />
                            </div>
                            <div className="form-group">
                                  <textarea value={description} onChange={onDescriptionChanged} className="form-control" style={{ height: 150 }} placeholder={t("Description")}></textarea>
                            </div>
                            <button onClick={()=>selectedCategory._id? updateCategory() : addCategory() } style={{ marginTop: "10px" }} className="blue_btn yellow_btn text-uppercase">{selectedCategory._id ? t("Update") : t("Add")}</button>
                        </aside>
                    </div>

                </div>

            </Modal>
            <ToastContainer />

        </div>
    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(Category)