import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import Pagination from "react-js-pagination";
import { userService } from '../_services';
import { useTranslation } from "react-i18next";

import {
    BrowserRouter as Router,
    Route,
    Link,
    useParams
} from "react-router-dom";


const Jobs = (props: any) => {
    const { t, i18n } = useTranslation();
    const [jobsList, setjobsList] = useState([{ _id: "", title: "", user_id: { name: "", last_name:"" }, hiredBidder: { name: "", last_name:"" }, category: { name: "" }, complete_status: false, start_date: "" }]);
    const [backupData, setbackupData] = useState([{ _id: "", title: "", user_id: { name: "", last_name:"" }, hiredBidder: { name: "", last_name:""}, category: { name: "" }, complete_status: false, start_date: "" }])
    const [fullData, setfullData] = useState([])
    const [perPage, setperPage] = useState(10)
    const [currentPage, setcurrentPage] = useState(1)
    const [search, setsearch] = useState('');

    let { id, type, name } = useParams();

    useEffect(() => type == 'customer' ? callJobsAPI() : callWorkerJobsAPI(), []);

    function callJobsAPI() {
        userService.getPostedJobs(id)
            .then(response => {
                console.log(response)
                var data = response.data.jobs;
                var newdata = [] as any;
                for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                    if (data[i]) {
                        console.log(data[i])
                        newdata.push(data[i]);
                    }
                }
                setbackupData(data);
                setjobsList(newdata)
                setfullData(data);
            })
            .catch(error => {
                console.log("errorrrrr",error.response)
            });
    }

    function onSearchChanged(event) {
        setsearch(search.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        // if (search.length) {
            var searchData = [] as any;
            console.log("backupData--------",backupData)
            for (var j in backupData) {
                var fullnameCus = backupData[j].user_id.name + " " + backupData[j].user_id.last_name;
                var fullnameWorker = "";
                if(backupData[j].hiredBidder){
                    fullnameWorker = backupData[j].hiredBidder.name + " " + backupData[j].hiredBidder.last_name;
                }
                
                if (backupData[j].title.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupData[j].user_id.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupData[j].user_id.last_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    fullnameCus.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    (backupData[j].hiredBidder && backupData[j].hiredBidder.name.toLowerCase().includes(event.target.value.toLowerCase())) ||
                    (backupData[j].hiredBidder && backupData[j].hiredBidder.last_name.toLowerCase().includes(event.target.value.toLowerCase())) ||
                    (backupData[j].hiredBidder && fullnameWorker.toLowerCase().includes(event.target.value.toLowerCase())) ||
                    backupData[j].start_date.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupData[j].category.name.toLowerCase().includes(event.target.value.toLowerCase())) {
                    searchData.push(backupData[j]);
                }
            }
            var data = searchData;
            var newdata = [] as any;
            for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                if (data[i]) {
                    console.log(data[i])
                    newdata.push(data[i]);
                }
            }
            setjobsList(newdata)
            setfullData(data);
            setcurrentPage(1)
        // }
    }

    function handlePageChange(pageNumber) {
        console.log(pageNumber)
        var newdata = [] as any;
        for (var i = ((pageNumber - 1) * perPage); i < (((pageNumber - 1) * perPage) + perPage); i++) {
            if (fullData[i]) {
                console.log(fullData[i])
                newdata.push(fullData[i]);
            }
        }
        setcurrentPage(pageNumber)
        setjobsList(newdata)
    }

    function callWorkerJobsAPI() {
        userService.getHiredJobs(id)
            .then(response => {
                console.log(response)
        
                var data = response.data.jobs;
                var newdata = [] as any;
                for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                    if (data[i]) {
                        console.log(data[i])
                        newdata.push(data[i]);
                    }
                }
                setbackupData(data);
                setjobsList(newdata)
                setfullData(data);

            })
            .catch(error => {
                console.log(error.response)
            });
    }


    function goToJobDetail(item) {
        window.location.href = '/jobdetail/' + item._id;
    }


    const { error, handleSubmit, pristine, reset, submitting } = props
    return (
        <div className="tables_area">
            <h2 className="pull-left">{type == 'customer' ? t('Jobs posted by ') + name : t('Hired Jobs')}</h2>
            <div className="input-group search_right pull-right">
                <input value={search} onChange={onSearchChanged} className="form-control" placeholder={t("Search")} />
            </div>
            <div className="clear"></div>
            <div className="white_box">
                <div className="table-responsive">

                    <table style={{ width: '100%' }} >
                        <thead>
                            <tr>
                                <th>{t("job title")}</th>
                                <th>{t("Customer name")}</th>
                                <th>{t("Hired worker")}</th>
                                <th>{t("Start date")}</th>
                                <th>{t("Category")}</th>
                                <th>{t("Job status")}</th>
                                <th>{t("View")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {jobsList.length && jobsList[0].title ?
                                jobsList.map((item, index) =>
                                    <tr>
                                        <td>{item.title}</td>
                                        <td>{item.user_id.name} {item.user_id.last_name}</td>
                                        <td>{item.hiredBidder ? item.hiredBidder.name + " " + item.hiredBidder.last_name : t('Not hired yet')}</td>
                                        <td>{item.start_date.split('T')[0]}</td>
                                        <td>{item.category.name}</td>
                                        <td>{item.complete_status ? t('Completed') : t('In-progress')}</td>
                                        <td>
                                            <a onClick={() => goToJobDetail(item)} className="action_btn"><i className="fa fa-eye" aria-hidden="true"></i></a>
                                        </td>
                                    </tr>
                                )
                                :

                                <tr> <td colSpan={7}><p className="no_found text-center">{t("No data found")}</p></td></tr>
                            }
                        </tbody>
                    </table>


                    <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={perPage}
                        totalItemsCount={fullData.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />

                </div>
            </div>
        </div>
    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(Jobs)