import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'

import { userService } from '../_services';

import {
    BrowserRouter as Router,
    Route,
    Link,
    useParams
} from "react-router-dom";
import { useTranslation } from "react-i18next";



const UserProfile = (props: any) => {
    const { t, i18n } = useTranslation();
    const [checked, setchecked] = useState(true);
    const [userprofile, setuserprofile] = useState({ name: "", last_name: "", email: "", profile_image: "", birthdate: "", location: "", bio: "", member_since: "", jobCompleted: 0, categories: "", subcategories: "", id_document: "", profile_document: "", documentsToShow: [{ document_url: "", name: "" }], country_code: "", phone_number: "", account_type: "", company_name: "", company_bio:"", year_founded:"", number_of_employee:"", city:"", state:"", country:"", appartment:"", zip_code:"", user_type:"",commune:"" });
    const [userid, setUserid] = useState('');

    function handleChange() {
        setchecked(!checked)
    }


    useEffect(() => callUserProfileAPI(), []);

    let { id } = useParams();
    // setUserid(id)

    function callUserProfileAPI() {
        userService.userprofile(id)
            .then(response => {
                console.log(response.data)
                var data = response.data.user;
                var categoriesnew = [];
                let documentsToShow: any[] = [];
                let subcategoriesnew: any[] = [];
                for (var i in data.categories) {
                    categoriesnew[i] = data.categories[i].name;
                    if (data.categories[i].document_url) {
                        documentsToShow.push(data.categories[i]);
                    }
                    for (var j in data.categories[i].sub_categories) {
                        subcategoriesnew.push(data.categories[i].sub_categories[j].name)
                    }
                }
                data.categories = categoriesnew.join(", ")
                data.subcategories = subcategoriesnew.join(", ")
                data.documentsToShow = documentsToShow;
                setuserprofile(data)
            })
            .catch(error => {
                console.log(error.response)
            });
    }

    const { error, handleSubmit, pristine, reset, submitting } = props
    return (
        <div>
            <h2>{t("User Profile")}</h2>
            <div className="white_box my_profile">
                <div className="row">
                    <aside className="col-lg-4 text-center">
                        <img src={userprofile.profile_image} alt="" className="img-circle profile_image" />
                    </aside>
                    <aside className="col-lg-4">
                        <div className="row">
                            <aside className="col-sm-12">
                                <label>{t("Name")}</label>
                                <h4>{userprofile.name} {userprofile.last_name}</h4>
                            </aside>

                        </div>
                        {userprofile.categories.length ?
                            <div className="row">
                                <aside className="col-sm-12">
                                    <label>{t("Categories")}</label>
                                    <h4>{userprofile.categories}</h4>
                                </aside>

                            </div>
                            : null}

                        <div className="row">
                            <aside className="col-sm-12">
                                <label>{t("Member since")}</label>
                                <h4>{userprofile.member_since}</h4>
                            </aside>

                        </div>
                        {/* <div className="row">
                            <aside className="col-sm-12">
                                <label>Date of Birth</label>
                                <h4>{userprofile.birthdate}</h4>
                            </aside>

                        </div> */}
                        {userprofile.year_founded ?
                            <div className="row">
                                <aside className="col-sm-12">
                                    <label>{t("Year Founded")}</label>
                                    <h4>{userprofile.year_founded}</h4>
                                </aside>
                            </div>
                            : null}

                        {userprofile.number_of_employee ?
                            <div className="row">
                                <aside className="col-sm-12">
                                    <label>{t("No. of Employees")}</label>
                                    <h4>{userprofile.number_of_employee}</h4>
                                </aside>
                            </div>
                            : null}
                        <div className="row">
                            {userprofile.id_document ?
                                <aside className="col-sm-12">
                                    <label>{t("Id Document")}</label>

                                    <h4><a href={userprofile.id_document} target="_black">{t("View document")}</a></h4>

                                </aside>
                                : null}

                        </div>
                        <div className="row">
                            <aside className="col-sm-12">
                                <label>{t("Address")}</label>
                                <h4>{userprofile.commune}{userprofile.commune? "," :null} {userprofile.appartment}{userprofile.appartment? "," :null} {userprofile.location}, {userprofile.city}, {userprofile.state}, {userprofile.country}{userprofile.zip_code? "," :null} {userprofile.zip_code}</h4>
                            </aside>


                        </div>
                        <div className="row">
                            <aside className="col-sm-12">
                                <label>{t("Phone")}</label>
                                <h4>{userprofile.country_code}{userprofile.phone_number}</h4>
                            </aside>
                        </div>

                    </aside>




                    <aside className="col-lg-4">
                        <div className="row">

                            <aside className="col-sm-12">
                                <label>{t("Email Address")}</label>
                                <h4>{userprofile.email}</h4>
                            </aside>

                        </div>
                        {userprofile.categories.length ?
                            <div className="row">

                                <aside className="col-sm-12">
                                    <label>{t("Sub-Categories")}</label>
                                    <h4>{userprofile.subcategories}</h4>
                                </aside>
                            </div>
                            : null}
                        {userprofile.user_type == "P" && userprofile.account_type ?
                            <div className="row">
                                <aside className="col-sm-12">
                                    <label>{t("Account Type")}</label>
                                    <h4>{userprofile.account_type == "FREELANCER" ? t("Freelancer") : t("Business")}</h4>
                                </aside>
                            </div>

                            : null}
                        {userprofile.company_name ?
                            <div className="row">
                                <aside className="col-sm-12">
                                    <label>{t("Company Name")}</label>
                                    <h4>{userprofile.company_name}</h4>
                                </aside>
                            </div>
                            : null}

                        {userprofile.company_bio ?
                            <div className="row">
                                <aside className="col-sm-12">
                                    <label>{t("Company Bio")}</label>
                                    <h4>{userprofile.company_bio}</h4>
                                </aside>
                            </div>
                            : null}

                        


                        <div className="row">
                            <aside className="col-sm-12">
                                <label>{t("Jobs Completed")}</label>
                                <h4>{userprofile.jobCompleted}</h4>
                            </aside>

                        </div>
                        <div className="row">
                            <aside className="col-sm-12">
                                <label>{t("Profile Document")}</label>
                                {userprofile.profile_document ?
                                    <h4><a href={userprofile.profile_document} target="_black">{t("View document")}</a></h4>
                                    :
                                    <h4>{t("No document uploaded")}</h4>
                                }
                            </aside>

                        </div>
                        <div className="row">

                            {userprofile.documentsToShow.length ?
                                <aside className="col-sm-12">
                                    <label>{t("Documents")}</label>
                                    {userprofile.documentsToShow.map((item, index) =>
                                        <div>
                                            <h5>{item.name}</h5>
                                            <h4><a href={item.document_url} target="_black">{t("View document")}</a></h4>
                                        </div>
                                    )}
                                </aside>
                                : null}
                        </div>
                        <div className="row">
                            <aside className="col-sm-12">
                                <label>{t("Bio")}</label>
                                <h4>{userprofile.bio}</h4>
                            </aside>
                        </div>



                    </aside>

                </div>
            </div>
        </div>
    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(UserProfile)