import { authHeader } from '../_helpers';
import { config } from '../config/config'
import axios from 'axios';
import jstz from 'jstz';

export const userService = {
    login,
    dashboard,
    customers,
    workers,
    userprofile,
    changeStatus,
    changeCertification,
    changeStatusJob,
    logout,
    userdetail,
    getbank,
    payments,
    adminProfile,
    updateExpiry,
    expiry,
    commission,
    updateCommission,
    contacts,
    jobDetail,
    adminProfileUpdate,
    changePassword,
    jobsmanagement,
    getPostedJobs,
    getHiredJobs,
    markAsPaidkMilestone,
    replyToUser,
    getAll,
    ratings,
    getRepliedMessage,
    categories,
    subCategories,
    updateSubCategory,
    addSubCategory,
    updateCategory,
    addCategory,
    privacyPolicy,
    updatePrivacy,
    getTerms,
    updateTerms,
    getHomeContent,
    updateHomeContent,
    contactInfo,
    updateContactInfo,
    howitworks,
    updateHowItWorks,
    homeImages,
    updateHomeBackground,
    addHomeBackground,
    geReportedJobList,
    reportjobDetail,
    enableDisableJob,
    geReportedUserist,
    jobReportUsers,
    userReportUsers,
    download
};
var configheaders: any = {
    headers: authHeader()
}

/* download list */
function download(status: any) {
    let url = `${config.apiUrl}/downloadExcelFile?status=${status}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function login(params: any) {
    let url = `${config.apiUrl}/login`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.post(url, params, config1)
}

function dashboard() {
    let url = `${config.apiUrl}/dashboard`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function customers(params) {
    let url = `${config.apiUrl}/customers?startDate=${params.startDate}&endDate=${params.endDate}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function adminProfileUpdate(params: any) {
    console.log("params", params)
    let url = `${config.apiUrl}/adminProfile`;
    let config1 = {
        headers: {
            "Accept": "application/json",
            "Content-Type": "multipart/form-data"
        }
    }
    return axios.put(url, params, config1)
}

function updateExpiry(params: any) {
    console.log("params", params)
    let url = `${config.apiUrl}/updateExpiry`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.put(url, params, config1)
}

function updateCommission(params: any) {
    console.log("params", params)
    let url = `${config.apiUrl}/updateCommission`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.put(url, params, config1)
}


function workers(params) {
    let url = `${config.apiUrl}/workers?startDate=${params.startDate}&endDate=${params.endDate}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function adminProfile() {
    let url = `${config.apiUrl}/adminProfile`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function jobReportUsers(params: any) {
    let url = `${config.apiUrl}/getReportedJobDetails/${params}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function userReportUsers(params: any) {
    let url = `${config.apiUrl}/reportUserDetails/${params}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function commission() {
    let url = `${config.apiUrl}/commsions`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function expiry() {
    let url = `${config.apiUrl}/expiry`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function contacts(params) {
    let url = `${config.apiUrl}/contact?startDate=${params.startDate}&endDate=${params.endDate}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}


function getPostedJobs(id) {
    let url = `${config.apiUrl}/jobsBycustomer/${id}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function jobDetail(id) {
    const timezone = jstz.determine();
    var timeeeee = timezone.name();

    let url = `${config.apiUrl}/jobdetail/${id}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json',
            'timezone': timeeeee
        }
    }
    return axios.get(url, config1)
}
function reportjobDetail(id) {
    const timezone = jstz.determine();
    var timeeeee = timezone.name();

    let url = `${config.apiUrl}/getReportJobDetail/${id}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json',
            'timezone': timeeeee
        }
    }
    return axios.get(url, config1)
}
function jobsmanagement(params) {
    let url = `${config.apiUrl}/jobsmanagement?startDate=${params.startDate}&endDate=${params.endDate}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function ratings(params) {
    let url = `${config.apiUrl}/ratings?startDate=${params.startDate}&endDate=${params.endDate}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function geReportedJobList() {
    let url = `${config.apiUrl}/getReportJobList`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function geReportedUserist() {
    let url = `${config.apiUrl}/getReportUserList`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}




function getHiredJobs(id) {
    let url = `${config.apiUrl}/jobsHiredWorker/${id}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}
function payments(params) {
    console.log("params----------->", params)
    let url = `${config.apiUrl}/milestones?startDate=${params.startDate}&endDate=${params.endDate}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}
function changePassword(params: any) {
    let url = `${config.apiUrl}/changePassword`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.post(url, params, config1)
}
function enableDisableJob(params: any) {
    let url = `${config.apiUrl}/enableDisableJob/${params.id}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.put(url, params, config1)
}

function markAsPaidkMilestone(params: any) {
    let url = `${config.apiUrl}/updateMilestonePayment`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.post(url, params, config1)
}

function replyToUser(params: any) {
    let url = `${config.apiUrl}/replyToUser`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json',
            "Accept-Language": localStorage.getItem("selectLanguage") == "ENGLISH" ? "en-US" : "fr-FR",
            "language": localStorage.getItem("selectLanguage") == "ENGLISH" ? "en" : "fr",
        }
    }
    return axios.post(url, params, config1)
}

function getRepliedMessage(id) {
    let url = `${config.apiUrl}/getRepliedMessage/${id}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json',
            "Accept-Language": localStorage.getItem("selectLanguage") == "ENGLISH" ? "en-US" : "fr-FR",
            "language": localStorage.getItem("selectLanguage") == "ENGLISH" ? "en" : "fr",
        }
    }
    return axios.get(url, config1)
}

function userprofile(id) {
    let url = `${config.apiUrl}/detail/${id}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function changeStatus(params: any) {
    // const [language, setLanguage] = useState(localStorage.getItem("selectLanguage")=="ENGLISH" ? "en": "fr");
    let url = `${config.apiUrl}/changeStatus`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json',
            "Accept-Language": localStorage.getItem("selectLanguage") == "ENGLISH" ? "en-US" : "fr-FR",
            "language": localStorage.getItem("selectLanguage") == "ENGLISH" ? "en" : "fr",
        }
    }
    return axios.post(url, params, config1)
}


function changeCertification(params: any) {
    let url = `${config.apiUrl}/changeCertification`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.post(url, params, config1)
}

function changeStatusJob(params: any) {
    let url = `${config.apiUrl}/changeStatusJob`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.post(url, params, config1)
}


function userdetail() {
    let url: any = `${config.apiUrl}/users/profile`;

    return axios.get(url, configheaders)

}

function getbank(user_id) {
    let url: any = `${config.apiUrl}/getBank/${user_id}`;

    return axios.get(url, configheaders)

}

function categories(language) {
    let url = `${config.apiUrl}/getCategories/${language}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function subCategories(id, language) {
    let url = `${config.apiUrl}/getSubCategories/${id}/${language}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function updateSubCategory(params: any, id, language) {
    let url = `${config.apiUrl}/updateSubCategories/${id}/${language}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.put(url, params, config1)
}

function addSubCategory(params: any, language) {
    let url = `${config.apiUrl}/subcategory/${language}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.post(url, params, config1)
}

function updateCategory(params: any, id, language) {
    let url = `${config.apiUrl}/updateCategories/${id}/${language}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.put(url, params, config1)
}

function addCategory(params: any, language) {
    let url = `${config.apiUrl}/addcategory/${language}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.post(url, params, config1)
}


function privacyPolicy(language) {
    let url = `${config.apiUrl}/getTermAndPrivacy/Privacy-Policy/${language}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function updatePrivacy(params: any, language) {
    let url = `${config.apiUrl}/termAndPrivacy/Privacy-Policy/${language}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.put(url, params, config1)
}

function getTerms(language) {
    let url = `${config.apiUrl}/getTermAndPrivacy/Term-Condition/${language}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function updateTerms(params: any, language) {
    let url = `${config.apiUrl}/termAndPrivacy/Term-Condition/${language}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.put(url, params, config1)
}

function getHomeContent(language) {
    let url = `${config.apiUrl}/homePage/${language}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function updateHomeContent(params: any, language) {
    let url = `${config.apiUrl}/homePage/${language}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.put(url, params, config1)
}

function contactInfo() {
    let url = `${config.apiUrl}/ContactInfo`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function updateContactInfo(params: any) {
    let url = `${config.apiUrl}/ContactInfo`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.put(url, params, config1)
}

function howitworks(type, language) {
    let url = `${config.apiUrl}/getHowItWork/${type}/${language}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function updateHowItWorks(params: any, type, language) {
    let url = `${config.apiUrl}/howItWork/${type}/${language}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.put(url, params, config1)
}

function homeImages(language) {
    let url = `${config.apiUrl}/getBackgroundImage/${language}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.get(url, config1)
}

function updateHomeBackground(params: any, id) {
    let url = `${config.apiUrl}/backgroundImage/${id}`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.put(url, params, config1)
}

function addHomeBackground(params: any) {
    let url = `${config.apiUrl}/addBackgroundImage`;
    let config1 = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.post(url, params, config1)
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('access_token');
}

function getAll() {
    const requestOptions: any = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}