import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { userService } from '../_services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";


const HomeContent = (props: any) => {
    const { t, i18n } = useTranslation();
    
    const [language, setLanguage] = useState(localStorage.getItem("selectLanguage")=="ENGLISH" ? "en": "fr");
    const [about, setAbout] = useState('');
    const [downloadAppCus, setDownloadAppCus] = useState('');
    const [downloadAppPro, setDownloadAppPro] = useState('');
    const [helpDescription, setHelpDescription] = useState('');

    useEffect(() => getHomeContent(language), []);

    useEffect(() => {
        var lang = localStorage.getItem("selectLanguage")=="ENGLISH" ? "en": "fr"
        setLanguage(lang)
        getHomeContent(lang)
    }, [localStorage.getItem("selectLanguage")]);


    function getHomeContent(language) {
        setLanguage(language)
        userService.getHomeContent(language)
            .then(response => {
                console.log(response.data)
                setAbout(response.data.data.about_us)
                setDownloadAppCus(response.data.data.download_content_customer)
                setDownloadAppPro(response.data.data.download_content_provider)
                setHelpDescription(response.data.data.help_support)
            })
            .catch(error => {
                console.log(error.response)
            });
    }


    function updatePrivacyPolicy() {
        if(!about){
            toast.error(t("Please enter About content."))
        } 
        else if(!downloadAppCus){
            toast.error(t("Please enter download app content for customer."))
        }
        else if(!downloadAppPro){
            toast.error(t("Please enter download app content for provider."))
        }
        else if(!helpDescription){
            toast.error(t("Please enter help and support content."))
        }
        else{
            var params ={
                about_us: about,
                download_content_customer: downloadAppCus,
                download_content_provider: downloadAppPro,
                help_support: helpDescription
            }
            userService.updateHomeContent(params, language)
                .then(response => {
                    console.log("response", response)
                    toast.success(t("Home Content updated successfully."))
                    getHomeContent(language);
                })
                .catch(error => {
                    console.log(error.response)
            }); 
        }
    }
    

    function onAboutChanged(event) {
        setAbout(about.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }
    function onDownloadChangedCus(event) {
        setDownloadAppCus(downloadAppCus.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }
    function onDownloadChangedPro(event) {
        setDownloadAppPro(downloadAppPro.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }
    function onHelpChanged(event) {
        setHelpDescription(helpDescription.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    return (
       
        <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 home-content">
              <div className="page_header privacy-policy ">
                <h2 className="subtitle">{t("Home Content")}</h2>
                    {/* <select
                        className="form-select"
                        onChange={(e) => getHomeContent(e.target.value)}
                        value={language}
                    >
                        <option value="en">English</option>
                        <option value="fr">French</option>
                    </select> */}
                    
              </div>
              <div className="App">

                <div className="row">
                    <aside className="col-sm-12">
                        <div className="form-group">
                             <h3 className="subtitle">{t("About Content")}</h3>
                            <textarea value={about} onChange={onAboutChanged} className="form-control" style={{ height: 150 }} placeholder={t("About Content")}></textarea>
                        </div>
                    </aside>
                </div>

                <div className="row">
                    <aside className="col-sm-12">
                        <div className="form-group">
                             <h3 className="subtitle">{t("Download App Content")} </h3>
                             <h5>{t("For Customer")}</h5>
                            <textarea value={downloadAppCus} onChange={onDownloadChangedCus} className="form-control" style={{ height: 100 }} placeholder={t("Download App Content")}></textarea>
                        </div>
                    </aside>
                </div>

                <div className="row">
                    <aside className="col-sm-12">
                        <div className="form-group">
                             <h5 className="provider-area">{t("For Provider")}</h5>
                            <textarea value={downloadAppPro} onChange={onDownloadChangedPro} className="form-control" style={{ height: 100 }} placeholder={t("Download App Content")}></textarea>
                        </div>
                    </aside>
                </div>

                <div className="row">
                    <aside className="col-sm-12">
                        <div className="form-group">
                             <h3 className="subtitle">{t("Help & Support Content")}</h3>
                            <textarea value={helpDescription} onChange={onHelpChanged} className="form-control" style={{ height: 150 }} placeholder={t("Help & Support Content")}></textarea>
                        </div>
                    </aside>
                </div>

              </div>
              <button className="save-bt sumbit-btn-bottom" onClick={updatePrivacyPolicy}>{t("Submit")}</button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>


       
    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(HomeContent)