import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { userService } from '../_services';
import Pagination from "react-js-pagination";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { useTranslation } from "react-i18next";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { config } from '../config/config';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

const PaymentManagement = (props: any) => {
    const { t, i18n } = useTranslation();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [downloadPdf, setDownLoadPdf] = useState("")
    const [escrowList, setescrowList] = useState([{ createdAt: "", payment: { transId: "" }, bid_id: { milestones: [{ price: "" }], bid_price: "", job_id: { title: '', finalPrice: 0, user_id: { name: '', last_name: '' }, complete_status: '' }, user_id: { name: '', last_name: '' } }, title: '', order: 0, price: '' }]);
    const [approvedList, setapprovedList] = useState([{ createdAt: "", payment: { transId: "" }, bid_id: { milestones: [{ price: "" }], bid_price: "", job_id: { title: '', finalPrice: 0, user_id: { name: '', last_name: '' }, complete_status: '' }, user_id: { name: '', last_name: '' } }, title: '', order: 0, price: '' }]);
    const [paidList, setpaidList] = useState([{ transId: "", payment: { transId: "" }, bid_id: { milestones: [{ price: "" }], bid_price: "", job_id: { title: '', finalPrice: 0, user_id: { name: '', last_name: '' }, complete_status: '' }, user_id: { name: '', last_name: '' } }, title: '', price: '', order: 0, paymentDate: '' }]);

    const [backupEscrowList, setbackupEscrowList] = useState([{ bid_id: { job_id: { title: '', finalPrice: 0, user_id: { name: '', last_name: '' }, complete_status: '' }, user_id: { name: '', last_name: '' } }, title: '', order: 0, price: '', paymentDate: '' }])
    const [backupApprovedList, setbackupApprovedList] = useState([{ bid_id: { job_id: { title: '', finalPrice: 0, user_id: { name: '', last_name: '' }, complete_status: '' }, user_id: { name: '', last_name: '' } }, title: '', order: 0, price: '', paymentDate: '' }])
    const [backupPaidList, setbackupPaidList] = useState([{ bid_id: { job_id: { title: '', finalPrice: 0, user_id: { name: '', last_name: '' }, complete_status: '' }, user_id: { name: '', last_name: '' } }, title: '', price: '', order: 0, paymentDate: '' }])
    const [activeTab, setActiveTab] = useState('Escrow');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setStartDate("")
        setEndDate("")
    };



    const [bankList, setbankList] = useState([{
        account_no: "", country_code: "", bank_name: "", branch_name: "", routing_no: "", payment_type: "", operator_type: "", phone_number: "", full_name: "",
        BankAddress: "",
        SwiftCode: ""
    }])

    const [search, setsearch] = useState('');
    const [search2, setsearch2] = useState('');
    const [search3, setsearch3] = useState('');

    const [fullData, setfullData] = useState([])
    const [perPage, setperPage] = useState(10)
    const [currentPage, setcurrentPage] = useState(1)
    const [fullData2, setfullData2] = useState([])
    const [perPage2, setperPage2] = useState(10)
    const [currentPage2, setcurrentPage2] = useState(1)
    const [fullData3, setfullData3] = useState([])
    const [perPage3, setperPage3] = useState(10)
    const [currentPage3, setcurrentPage3] = useState(1)
    const [isLoading, setisLoading] = useState(1);
    const [editModal, seteditModal] = useState(false);
    const [commsion, setcommsion] = useState('');

    useEffect(() => {
        callWorkersAPI(startDate, endDate);
    }, []);

    useEffect(() => {
        callCommissionAPI()
    }, []);

    const resetDates = () => {
        callWorkersAPI("", "");
        setStartDate('');
        setEndDate('');
    };



    function callCommissionAPI() {
        userService.commission()
            .then(response => {
                console.log(response.data)
                setcommsion(response.data.commission.commision)
            })
            .catch(error => {
                console.log(error.response)
            });
    }


    function callWorkersAPI(startDate: string, endDate: string) {
        const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
        const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';
        const params = { startDate: startDate, endDate: endDate };
        if (formattedStartDate) params.startDate = formattedStartDate;
        if (formattedEndDate) params.endDate = formattedEndDate;
        userService.payments(params)
            .then(response => {
                console.log(response.data)
                var data = response.data.escrowed;
                var newdata = [] as any;
                for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                    if (data[i]) {
                        newdata.push(data[i]);
                    }
                }
                setescrowList(newdata)
                setfullData(data);
                setbackupEscrowList(data)

                var data2 = response.data.approved;
                var newdata2 = [] as any;
                for (var i = (0 * perPage2); i < ((0 * perPage2) + perPage2); i++) {
                    if (data2[i]) {
                        newdata2.push(data2[i]);
                    }
                }
                console.log("newdata2", newdata2)
                setapprovedList(newdata2)
                setfullData2(data2);
                setbackupApprovedList(data2)

                var data3 = response.data.paid;
                var newdata3 = [] as any;
                for (var i = (0 * perPage3); i < ((0 * perPage3) + perPage3); i++) {
                    if (data3[i]) {
                        newdata3.push(data3[i]);
                    }
                }
                console.log("newdata3", newdata3)
                setpaidList(newdata3)
                setfullData3(data3);
                setbackupPaidList(data3)
                setisLoading(2)
            })
            .catch(error => {
                console.log(error.response)
            });
    }

    function handlePageChange(pageNumber) {
        console.log(pageNumber)
        var newdata = [] as any;
        for (var i = ((pageNumber - 1) * perPage); i < (((pageNumber - 1) * perPage) + perPage); i++) {
            if (fullData[i]) {
                console.log(fullData[i])
                newdata.push(fullData[i]);
            }
        }
        setcurrentPage(pageNumber)
        setescrowList(newdata)
    }


    function handlePageChange2(pageNumber) {
        console.log(pageNumber)
        var newdata = [] as any;
        for (var i = ((pageNumber - 1) * perPage); i < (((pageNumber - 1) * perPage) + perPage); i++) {
            if (fullData2[i]) {
                console.log(fullData2[i])
                newdata.push(fullData2[i]);
            }
        }
        setcurrentPage2(pageNumber)
        setapprovedList(newdata)
    }


    function handlePageChange3(pageNumber) {
        console.log(pageNumber)
        var newdata = [] as any;
        for (var i = ((pageNumber - 1) * perPage); i < (((pageNumber - 1) * perPage) + perPage); i++) {
            if (fullData3[i]) {
                console.log(fullData3[i])
                newdata.push(fullData3[i]);
            }
        }
        setcurrentPage3(pageNumber)
        setpaidList(newdata)
    }



    function markMilestonePaid(item) {
        let result = window.confirm(t('Are you sure you want to mark this as Paid?'))
        console.log("item", item)
        if (result) {
            setisLoading(1)
            var params = {
                milestone_id: item._id,
                status: '6'
            }
            userService.markAsPaidkMilestone(params)
                .then(response => {
                    console.log("response.user", response)
                    setisLoading(2)
                    setTimeout(
                        () => {
                            toast.success(t("Milestone marked as paid successfully."));
                        },
                        500
                    );

                    callWorkersAPI(startDate, endDate);
                })
                .catch(error => {
                    console.log(error.response)
                });
        }
    }



    function onSearchChanged(event) {
        setsearch(search.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        if (search.length) {
            var searchData = [] as any;
            for (var j in backupEscrowList) {
                var fullnameCus = backupEscrowList[j].bid_id.job_id.user_id.name + " " + backupEscrowList[j].bid_id.job_id.user_id.last_name;
                var fullnameWorker = backupEscrowList[j].bid_id.user_id.name + " " + backupEscrowList[j].bid_id.user_id.last_name;
                if (backupEscrowList[j].bid_id.job_id.title.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupEscrowList[j].bid_id.job_id.user_id.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupEscrowList[j].bid_id.job_id.user_id.last_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    fullnameCus.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupEscrowList[j].bid_id.user_id.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupEscrowList[j].bid_id.user_id.last_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    fullnameWorker.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupEscrowList[j].title.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupEscrowList[j].price.toLowerCase().includes(event.target.value.toLowerCase())) {
                    searchData.push(backupEscrowList[j]);
                }
            }
            var data = searchData;
            var newdata = [] as any;
            for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                if (data[i]) {
                    newdata.push(data[i]);
                }
            }
            setescrowList(newdata)
            setfullData(data);
            setcurrentPage(1)
        }
    }


    function onSearchChanged2(event) {
        setsearch2(search2.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        if (search2.length) {
            var searchData = [] as any;
            for (var j in backupApprovedList) {
                var fullnameCus = backupApprovedList[j].bid_id.job_id.user_id.name + " " + backupApprovedList[j].bid_id.job_id.user_id.last_name;
                var fullnameWorker = backupApprovedList[j].bid_id.user_id.name + " " + backupApprovedList[j].bid_id.user_id.last_name;
                if (backupApprovedList[j].bid_id.job_id.title.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupApprovedList[j].bid_id.job_id.user_id.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupApprovedList[j].bid_id.job_id.user_id.last_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    fullnameCus.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupApprovedList[j].bid_id.user_id.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupApprovedList[j].bid_id.user_id.last_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    fullnameWorker.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupApprovedList[j].title.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupApprovedList[j].price.toLowerCase().includes(event.target.value.toLowerCase())) {
                    searchData.push(backupApprovedList[j]);
                }
            }
            var data = searchData;
            var newdata = [] as any;
            for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                if (data[i]) {
                    newdata.push(data[i]);
                }
            }
            setapprovedList(newdata)
            setfullData2(data);
            setcurrentPage2(1)
        }
    }


    function onSearchChanged3(event) {
        setsearch3(search3.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        if (search3.length) {
            var searchData = [] as any;
            for (var j in backupPaidList) {
                var fullnameWorker = backupPaidList[j].bid_id.user_id.name + " " + backupPaidList[j].bid_id.user_id.last_name;
                var fullnameCus = backupPaidList[j].bid_id.job_id.user_id.name + " " + backupPaidList[j].bid_id.job_id.user_id.last_name;
                if (backupPaidList[j].bid_id.job_id.title.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupPaidList[j].bid_id.job_id.user_id.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupPaidList[j].bid_id.job_id.user_id.last_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    fullnameCus.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupPaidList[j].bid_id.user_id.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupPaidList[j].bid_id.user_id.last_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    fullnameWorker.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupPaidList[j].title.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupPaidList[j].price.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupPaidList[j].paymentDate.toLowerCase().includes(event.target.value.toLowerCase())) {
                    searchData.push(backupPaidList[j]);
                }
            }
            var data = searchData;
            var newdata = [] as any;
            for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                if (data[i]) {
                    newdata.push(data[i]);
                }
            }
            setpaidList(newdata)
            setfullData3(data);
            setcurrentPage3(1)
        }
    }


    function openBankDetails(item) {
        setisLoading(1)
        userService.getbank(item?.bid_id?.user_id?._id)
            .then(response => {
                console.log("response", response)
                setisLoading(2)
                // setTimeout(
                //     () => {
                setbankList(response.data.bank ? [response.data.bank] : [])
                seteditModal(true)
                //     },
                //     1000
                // );
            })
            .catch(error => {
                console.log(error.response)
            });
    }


    function closeModal() {
        seteditModal(false)
        setbankList([])
    }
    const customStyles1 = {
        overlay: {
            display: "block",
            paddingRight: "15px",
            backgroundColor: 'rgba(51,51,51,0.8)',
            zIndex: 99
        },

        content: {
            position: "",
            top: "",
            right: "",
            bottom: "",
            left: ""
        }
    };

    const downloadList = (status) => {
        userService.download(status)
            .then(response => {
                const downloadUrl = config.apiurl + "/" + response.data.path;
                console.log("Download URL:", downloadUrl);
                window.open(downloadUrl, '_blank');
            })
            .catch(error => {
                console.error('Download error:', error);
            });
    };

    const { error, handleSubmit, pristine, reset, submitting } = props
    return (
        <section className="my_job_tab_box">
            {isLoading == 1 &&
                <div className="loader-back">
                    <div className="loaderrrr">
                        <span>
                            <img src={require("../assets/images/loader.gif")} alt="" />
                        </span>

                    </div>
                </div>
            }
            <div className="tab_heading_content">
                <ul className="nav nav-tabs">
                    <li className={activeTab === 'Escrow' ? 'active active_list' : 'active_list'}>
                        <a onClick={() => handleTabChange('Escrow')} data-toggle="tab" href="#escrowed">{t("Escrow")}</a>
                    </li>
                    <li className={activeTab === 'Approved' ? 'upcoming_list active' : 'upcoming_list'}>
                        <a onClick={() => handleTabChange('Approved')} data-toggle="tab" href="#approved">{t("Approved")}</a>
                    </li>
                    <li className={activeTab === 'Paid' ? 'past-list active' : 'past-list'}>
                        <a onClick={() => handleTabChange('Paid')} data-toggle="tab" href="#paid">{t("Paid")}</a>
                    </li>
                </ul>
            </div>
            <div className="tab-content">
                <div id="escrowed" className="tab-pane fade in active">
                    <div className="tables_area">
                        <div className='main-outer-div'>
                            <h2 className="pull-left">{t("Payment in Escrow")}</h2>
                            <div className="input-group search_right pull-right outer-main-search">
                                <input value={search} onChange={onSearchChanged} className="form-control" placeholder={t("Search")} />
                            </div>
                        </div>


                        <div className="filter_date">
                            <div>
                                <label>Start Date</label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    placeholder={t("Start Date")}
                                    className="form-control"
                                />
                            </div>
                            <div>
                                <label>End Date</label>
                                <input
                                    type="date"
                                    min={startDate}
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    placeholder={t("End Date")}
                                    className="form-control"
                                />
                            </div>
                            {startDate && (
                                <>
                                    <button onClick={() => callWorkersAPI(startDate, endDate)} className="btn btn-primary">
                                        Apply
                                    </button>
                                    <button onClick={resetDates} className="btn btn-secondary">
                                        Reset
                                    </button>
                                </>
                            )}

                            <button onClick={(e) => downloadList(activeTab)} className="btn btn-primary">
                                <FontAwesomeIcon icon={faDownload} /> {t("Download")}
                            </button>
                        </div>

                        <div className="clear"></div>
                        <div className="white_box">
                            <div className="table-responsive">

                                <table style={{ width: '100%' }} >
                                    <thead>
                                        <tr>
                                            <th>{t("transaction ID")}</th>
                                            <th>{t("Job Title")}</th>
                                            <th>{t("Customer")}</th>
                                            <th>{t("Worker")}</th>
                                            <th>{t("Milestone")}</th>
                                            <th>{t("Total Job Amount")}</th>
                                            <th>{t("Milestone Amount")}</th>
                                            <th>{t("Job status")}</th>
                                            <th>{t("Date of payment")}</th>
                                            <th>{t("Bank Details")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {escrowList.length && escrowList[0].title ?
                                            escrowList.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>{item?.payment?.transId ? item?.payment?.transId : "-"}</td>
                                                        <td>{item?.bid_id?.job_id?.title}</td>
                                                        <td>{item?.bid_id?.job_id?.user_id?.name} {item?.bid_id?.job_id?.user_id?.last_name}</td>
                                                        <td>{item?.bid_id?.user_id?.name} {item?.bid_id?.user_id?.last_name}</td>
                                                        <td>{t("Milestone")} {item?.order}</td>
                                                        <td>
                                                            <div className="tooltip">
                                                                CFA {Number(item?.bid_id?.bid_price).toLocaleString('de-DE')} {" "}
                                                                <i className="fa fa-info-circle cursor" aria-hidden="true"></i>
                                                                <span className="tooltiptext">
                                                                    <span>Total Job Amount: {"CFA"} {Number(item?.bid_id?.bid_price).toLocaleString('de-DE')} </span><br />
                                                                    {item?.bid_id?.milestones && item?.bid_id?.milestones?.map((milestone, index) => (
                                                                        <React.Fragment key={index}>
                                                                            <span>{`Milestone${index + 1}: CFA ${Number(milestone?.price).toLocaleString('de-DE')}`}</span><br />
                                                                        </React.Fragment>
                                                                    ))}
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td>CFA {Number(item.price).toLocaleString('de-DE')}</td>
                                                        <td className='white-space-nowrap'>{item?.bid_id?.job_id?.complete_status ? 'Completed' : 'In-progress'}</td>
                                                        <td className='white-space-nowrap'>
                                                            {new Date(item?.createdAt).toLocaleString('en-US', {
                                                                year: 'numeric',
                                                                month: '2-digit',
                                                                day: '2-digit',
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                hour12: true
                                                            })}
                                                        </td>
                                                        <td className='white-space-nowrap'><a onClick={() => openBankDetails(item)} className="view_jobs">{t("View Details")}</a></td>
                                                    </tr>

                                                )
                                            }
                                            )
                                            :
                                            isLoading != 1 ?
                                                <tr> <td colSpan={67}><p className="no_found text-center">{t("No data found")}</p></td></tr>
                                                : null}
                                    </tbody>
                                </table>

                            </div>
                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={perPage}
                                totalItemsCount={fullData.length}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>


                <div id="approved" className="tab-pane fade">
                    <div className="tables_area">
                        <div className='main-outer-div'>
                            <h2 className="pull-left">{t("Approved Payments")}</h2>
                            <div className="input-group search_right pull-right">
                                <input value={search2} onChange={onSearchChanged2} className="form-control" placeholder={t("Search")} />
                            </div>
                        </div>
                        <div className="filter_date">
                            <div>
                                <label>Start Date</label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    placeholder={t("Start Date")}
                                    className="form-control"
                                />
                            </div>
                            <div>
                                <label>End Date</label>
                                <input
                                    type="date"
                                    min={startDate}
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    placeholder={t("End Date")}
                                    className="form-control"
                                />
                            </div>
                            {startDate && (
                                <>
                                    <button onClick={() => callWorkersAPI(startDate, endDate)} className="btn btn-primary">
                                        Apply
                                    </button>
                                    <button onClick={resetDates} className="btn btn-secondary">
                                        Reset
                                    </button>
                                </>
                            )}

                            <button onClick={(e) => downloadList(activeTab)} className="btn btn-primary">
                                <FontAwesomeIcon icon={faDownload} /> {t("Download")}
                            </button>
                        </div>
                        <div className="clear"></div>
                        <div className="white_box">
                            <div className="table-responsive">
                                <table style={{ width: '100%' }} >
                                    <thead>
                                        <tr>
                                            <th>{t("translation ID")}</th>
                                            <th>{t("Job Title")}</th>
                                            <th>{t("Customer")}</th>
                                            <th>{t("Worker")}</th>
                                            <th>{t("Milestone")}</th>
                                            <th>{t("Total Job Amount")}</th>
                                            <th>{t("Milestone Amount")}</th>
                                            <th>{t("Commission")}</th>
                                            <th>{t("Need to pay")}</th>
                                            <th>{t("Job status")}</th>
                                            <th>{t("Date of payment")}</th>
                                            <th>{t("Bank Details")}</th>
                                            <th>{t("Action")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {approvedList.length && approvedList[0].title ?
                                            approvedList.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>{item?.payment?.transId ? item?.payment?.transId : "-"}</td>
                                                        <td>{item?.bid_id?.job_id?.title}</td>
                                                        <td>{item?.bid_id?.job_id?.user_id?.name} {item?.bid_id?.job_id?.user_id?.last_name} </td>
                                                        <td>{item?.bid_id?.user_id?.name} {item?.bid_id?.user_id?.last_name}</td>
                                                        <td>{t("Milestone")} {item?.order}</td>
                                                        <td>
                                                            <div className="tooltip">
                                                                CFA {Number(item?.bid_id?.bid_price).toLocaleString('de-DE')} {" "}
                                                                <i className="fa fa-info-circle cursor" aria-hidden="true"></i>
                                                                <span className="tooltiptext">
                                                                    <span>Total Job Amount: {"CFA"} {Number(item?.bid_id?.bid_price).toLocaleString('de-DE')} </span><br />
                                                                    {item?.bid_id?.milestones && item?.bid_id?.milestones?.map((milestone, index) => (
                                                                        <React.Fragment key={index}>
                                                                            <span>{`Milestone${index + 1}: CFA ${Number(milestone?.price).toLocaleString('de-DE')}`}</span><br />
                                                                        </React.Fragment>
                                                                    ))}
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td>CFA {Number(item?.price).toLocaleString('de-DE')}</td>
                                                        <td>CFA {Number(((parseInt(commsion) / 100) * parseInt(item.price)).toFixed(2)).toLocaleString('de-DE')}</td>
                                                        <td>CFA {Number(parseInt(item.price) - (((parseInt(commsion) / 100) * parseInt(item?.price)))).toLocaleString('de-DE')}</td>
                                                        <td className='white-space-nowrap'>{item?.bid_id?.job_id?.complete_status ? 'Completed' : 'In-progress'}</td>
                                                        <td className='white-space-nowrap'>
                                                            {new Date(item?.createdAt).toLocaleString('en-US', {
                                                                year: 'numeric',
                                                                month: '2-digit',
                                                                day: '2-digit',
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                hour12: true
                                                            })}
                                                        </td>


                                                        <td className='white-space-nowrap'><a onClick={() => openBankDetails(item)} className="view_jobs">{t("View Details")}</a></td>
                                                        <td ><button className="mark_read_btn" onClick={() => markMilestonePaid(item)}>{t("Mark as Paid")}</button></td>
                                                    </tr>
                                                )
                                            }

                                            )
                                            :
                                            isLoading != 1 ?
                                                <tr> <td colSpan={10}><p className="no_found text-center">{t("No data found")}</p></td></tr>
                                                : null}


                                    </tbody>
                                </table>
                            </div>
                            <Pagination
                                activePage={currentPage2}
                                itemsCountPerPage={perPage2}
                                totalItemsCount={fullData2.length}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange2}
                            />
                        </div>
                    </div>
                </div>


                <div id="paid" className="tab-pane fade">
                    <div className="tables_area">
                        <div className='main-outer-div'>
                            <h2 className="pull-left">{t("Paid")}</h2>
                            <div className="input-group search_right pull-right">
                                <input value={search3} onChange={onSearchChanged3} className="form-control" placeholder={t("Search")} />
                            </div>
                        </div>
                        <div className="filter_date">
                            <div>
                                <label>Start Date</label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    placeholder={t("Start Date")}
                                    className="form-control"
                                />
                            </div>
                            <div>
                                <label>End Date</label>
                                <input
                                    type="date"
                                    min={startDate}
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    placeholder={t("End Date")}
                                    className="form-control"
                                />
                            </div>
                            {startDate && (
                                <>
                                    <button onClick={() => callWorkersAPI(startDate, endDate)} className="btn btn-primary">
                                        Apply
                                    </button>
                                    <button onClick={resetDates} className="btn btn-secondary">
                                        Reset
                                    </button>
                                </>
                            )}

                            <button onClick={(e) => downloadList(activeTab)} className="btn btn-primary">
                                <FontAwesomeIcon icon={faDownload} /> {t("Download")}
                            </button>
                        </div>
                        <div className="clear"></div>
                        <div className="white_box">
                            <div className="table-responsive">
                                <table style={{ width: '100%' }} >
                                    <thead>
                                        <tr>
                                            <th>{t("translation ID")}</th>
                                            <th>{t("Job Title")}</th>
                                            <th>{t("Customer")}</th>
                                            <th>{t("Worker")}</th>
                                            <th>{t("Milestone")}</th>
                                            <th>{t("Total Job Amount")}</th>
                                            <th>{t("Milestone Amount")}</th>
                                            <th>{t("Commission")}</th>
                                            <th>{t("Paid amount")}</th>
                                            <th>{t("Job status")}</th>
                                            <th>{t("Date of payment")}</th>
                                            <th>{t("Bank Details")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paidList?.length && paidList[0]?.title ?
                                            paidList?.map((item, index) => {

                                                return (
                                                    <tr>
                                                        <td>{item?.payment?.transId ? item?.payment?.transId : "-"}</td>
                                                        <td>{item?.bid_id?.job_id?.title}</td>
                                                        <td>{item?.bid_id?.job_id?.user_id?.name} {item?.bid_id?.job_id?.user_id?.last_name}</td>
                                                        <td>{item?.bid_id?.user_id?.name} {item?.bid_id?.user_id?.last_name}</td>
                                                        <td>{t("Milestone")} {item?.order}</td>
                                                        <td>
                                                            <div className="tooltip">
                                                                CFA {Number(item?.bid_id?.bid_price).toLocaleString('de-DE')} {" "}
                                                                <i className="fa fa-info-circle cursor" aria-hidden="true"></i>
                                                                <span className="tooltiptext">
                                                                    <span>Total Job Amount: {"CFA"} {Number(item?.bid_id?.bid_price).toLocaleString('de-DE')} </span><br />
                                                                    {item?.bid_id?.milestones && item?.bid_id?.milestones?.map((milestone, index) => (
                                                                        <React.Fragment key={index}>
                                                                            <span>{`Milestone${index + 1}: CFA ${Number(milestone?.price).toLocaleString('de-DE')}`}</span><br />
                                                                        </React.Fragment>
                                                                    ))}
                                                                </span>
                                                            </div>
                                                        </td>

                                                        <td>CFA {Number(item?.price).toLocaleString('de-DE')}</td>
                                                        <td>CFA {Number(((parseInt(commsion) / 100) * parseInt(item?.price)).toFixed(2)).toLocaleString('de-DE')}</td>
                                                        <td>CFA {Number(parseInt(item.price) - (((parseInt(commsion) / 100) * parseInt(item?.price)))).toLocaleString('de-DE')}</td>
                                                        <td className='white-space-nowrap'>{item?.bid_id?.job_id?.complete_status ? 'Completed' : 'In-progress'}</td>
                                                        <td className='white-space-nowrap'>
                                                            {new Date(item?.paymentDate).toLocaleString('en-US', {
                                                                year: 'numeric',
                                                                month: '2-digit',
                                                                day: '2-digit',
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                hour12: true
                                                            })}
                                                        </td>


                                                        <td className='white-space-nowrap'><a onClick={() => openBankDetails(item)} className="view_jobs">{t("View Details")}</a></td>
                                                    </tr>
                                                )

                                            }

                                            )
                                            :
                                            isLoading != 1 ?
                                                <tr> <td colSpan={10}><p className="no_found text-center">{t("No data found")}</p></td></tr>
                                                : null}


                                    </tbody>
                                </table>
                            </div>
                            <Pagination
                                activePage={currentPage3}
                                itemsCountPerPage={perPage3}
                                totalItemsCount={fullData3.length}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange3}
                            />
                        </div>
                    </div>
                </div>

                <Modal
                    ariaHideApp={false}
                    isOpen={editModal}
                    onRequestClose={closeModal}

                    className={
                        "modal-content  new_modal_content add_profile_modal-content payout-popup"}
                    contentLabel="Example Modal"

                    style={customStyles1}
                >
                    <div className="modal-header">
                        <h4 className="modal-title">{t("Payout Details")}<span><i className="fa fa-times-circle-o" onClick={closeModal} aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                    </div>
                    <div className="modal-body">
                        <div className="row bank_detail">
                            {bankList.length ?
                                bankList.map((item, index) => {
                                    return (
                                        <>
                                            {item.payment_type !== "MOBILE-MONEY" ?
                                                <aside className="col-sm-12">

                                                    <div className="row">
                                                        <aside className="col-sm-12">
                                                            <div className='payout-detail-inner'>
                                                                <label>{t("Bank Name")}</label>
                                                                <h4>{item.bank_name}</h4>
                                                            </div>
                                                        </aside>

                                                    </div>

                                                    <div className="row">
                                                        <aside className="col-sm-12">
                                                            <div className='payout-detail-inner'>
                                                                <label>{t("Bank Address")}</label>
                                                                <h4>{item.BankAddress}</h4>
                                                            </div>

                                                        </aside>

                                                    </div>


                                                    <div className="row">
                                                        <aside className="col-sm-12">
                                                            <div className='payout-detail-inner'>
                                                                <label>{t("Swift Code")}</label>
                                                                <h4>{item.SwiftCode}</h4>
                                                            </div>

                                                        </aside>

                                                    </div>

                                                    <div className="row">
                                                        <aside className="col-sm-12">
                                                            <div className='payout-detail-inner'>
                                                                <label>{t("Account Number")}</label>
                                                                <h4>{item.account_no}</h4>
                                                            </div>
                                                        </aside>

                                                    </div>
                                                    <div className="row">
                                                        <aside className="col-sm-12">
                                                            <div className='payout-detail-inner'>
                                                                <label>{t("Routing Number")}</label>
                                                                <h4>{item.routing_no}</h4>
                                                            </div>
                                                        </aside>

                                                    </div>
                                                </aside>
                                                :

                                                <aside className="col-sm-12">
                                                    <div className="row">
                                                        <aside className="col-sm-12">
                                                            <div className='payout-detail-inner'>
                                                                <label>{t("Full Name")}</label>
                                                                <h4>{item.full_name}</h4>
                                                            </div>

                                                        </aside>

                                                    </div>

                                                    <div className="row">
                                                        <aside className="col-sm-12">
                                                            <div className='payout-detail-inner'>
                                                                <label>{t("Phone Number")}</label>
                                                                <h4>+{item?.country_code} {item.phone_number}</h4>
                                                            </div>
                                                        </aside>

                                                    </div>


                                                    <div className="row">
                                                        <aside className="col-sm-12">
                                                            <div className='payout-detail-inner'>
                                                                <label>{t("Operator")}</label>
                                                                <h4>{item.operator_type}</h4>
                                                            </div>
                                                        </aside>

                                                    </div>
                                                </aside>
                                            }
                                        </>
                                    )
                                })
                                : <p className="no_found text-center">{t("No bank added")}</p>
                            }
                        </div>

                    </div>

                </Modal>

            </div>

            <ToastContainer />
        </section>

    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(PaymentManagement)