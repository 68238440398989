import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { userService } from '../_services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useTranslation } from "react-i18next";



const PrivacyPolicy = (props: any) => {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(localStorage.getItem("selectLanguage")=="ENGLISH" ? "en": "fr");
    const [content, setContent] = useState('<p>Hello from CKEditor 5!</p>');

    useEffect(() => getPrivacyPolicy(language), []);

    useEffect(() => {
        var lang = localStorage.getItem("selectLanguage")=="ENGLISH" ? "en": "fr"
        setLanguage(lang)
        getPrivacyPolicy(lang)
    }, [localStorage.getItem("selectLanguage")]);


    function getPrivacyPolicy(language) {
        setLanguage(language)
        userService.privacyPolicy(language)
            .then(response => {
                console.log(response.data)
                setContent(response.data.data.description)
            })
            .catch(error => {
                console.log(error.response)
            });
    }


    function updatePrivacyPolicy() {
        if(!content){
            toast.error(t("Please enter privacy policy."))
        }
        else{
            var params ={
                description: content
            }
            userService.updatePrivacy(params, language)
                .then(response => {
                    console.log("response", response)
                    toast.success(t("Privacy Policy updated successfully."))
                    getPrivacyPolicy(language);
                })
                .catch(error => {
                    console.log(error.response)
            }); 
        }
    }
    



    return (
       
        <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="page_header privacy-policy">
                <h2 className="subtitle">{t("Privacy Policy")}</h2>
                    {/* <select
                        className="form-select"
                        onChange={(e) => getPrivacyPolicy(e.target.value)}
                        value={language}
                    >
                        <option value="en">English</option>
                        <option value="fr">French</option>
                    </select> */}
                    
              </div>
              <div className="App">
                <CKEditor editor={ClassicEditor} data={content} onChange={(event, editor) => { content.length == 0 ? setContent(editor.getData().replace(/ /g, "")) : setContent(editor.getData()) }} 
                config={{
                  toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'underline',
                      'strikethrough',
                      '|',
                      'alignment',
                      '|',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'indent',
                      'outdent',
                      '|',
                      // 'imageUpload',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      '|',
                      'undo',
                      'redo',
                  ],
              }}/>
              </div>
              <button className="save-bt sumbit-btn-bottom" onClick={updatePrivacyPolicy}>{t("Submit")}</button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>


       
    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(PrivacyPolicy)