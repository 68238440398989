import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { useTranslation } from "react-i18next";
import { userService } from '../_services';




const Changepassword = (props: any) => {
    const { t, i18n } = useTranslation();
    const [oldPassword, setoldPassword] = useState('');
    const [newPassword, setnewPassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');


    function changePassword() {
        if (!oldPassword) {
            alert("Please enter old password")
        }
        else if (!newPassword) {
            alert("Please enter new password")
        }
        else if (!confirmPassword) {
            alert("Please enter confirm password")
        }
        else if (newPassword != confirmPassword) {
            alert("New password and confirm password must be same")
        }
        else {
            var params = {
                old_password: oldPassword,
                new_password: newPassword
            }
            userService.changePassword(params)
                .then(response => {
                    console.log("response.user", response)
                    alert(response.data.message)
                    setoldPassword('');
                    setnewPassword('');
                    setconfirmPassword('');
                })
                .catch(error => {
                    console.log(error.response)
                });

        }

    }

    
    function onoldPasswordChanged(event) {
        setoldPassword(oldPassword.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    function onnewPasswordChanged(event) {
        setnewPassword(newPassword.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }
    function onconfirmPasswordChanged(event) {
        setconfirmPassword(confirmPassword.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    const { error, handleSubmit, pristine, reset, submitting } = props
    return (
        <div className="tables_area">
            <h2>{t("Change Password")}</h2>
            <div className="white_box my_profile">
                <div className="">
                   
                    <aside className="padding_20">
                        <div className="row">
                            <aside className="col-sm-6">
                                <label>{t("Old Password")}</label>
                                <input value={oldPassword} onChange={onoldPasswordChanged} className="form-control" type="password" />
                            </aside>
                          
                        </div>
                        <div className="row">
                            <aside className="col-sm-6">
                                <label>{t("New Password")}</label>
                                <input value={newPassword} onChange={onnewPasswordChanged}  className="form-control" type="password" />
                            </aside>
                           
                        </div>
                        <div className="row">
                            <aside className="col-sm-6">
                                <label>{t("Confirm Password")}</label>
                                <input value={confirmPassword} onChange={onconfirmPasswordChanged}  className="form-control" type="password" />
                            </aside>
                           
                        </div>
                        <button onClick={() => changePassword()} className="blue_btn yellow_btn text-uppercase">{t("Update")}</button>
                    </aside>
                </div>
            </div>
        </div>

    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(Changepassword)