import * as React from "react";
import { lazy, Suspense } from "react";
import './App.css';
import store from './store';
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Layout } from './components/common/Layout'
import Home from './components/Home'
import Login from './components/Login'
import ProviderManagement from './components/Provider'
import PaymentManagement from './components/Payment'
import Customer from './components/Customer'
import UserProfile from './components/UserProfile'
import RequestProfile from './components/RequestProfile'
import Jobs from './components/Jobs'
import JobsManagement from './components/JobsManagement'
import Rating from './components/Rating'
import Adminprofile from './components/Adminprofile'
import EditAdmin from './components/EditAdmin'
import ChangePassword from './components/ChangePassword'
import Expiry from './components/Expiry'
import Contact from './components/Contact'
import JobDetail from './components/JobDetail'
import Commision from './components/Commision'
import Category from './components/Category'
import SubCategory from './components/SubCategory'
import PrivacyPolicy from './components/PrivacyPolicy'
import TermsConditions from './components/TermsConditions'
import HomeContent from './components/HomeContent'
import ContactInfo from './components/ContactInfo'
import HowItWorks from './components/HowItWorks'
import HomeImages from './components/HomeImages'
import ReportPage from "./components/ReportPage";



const access_token = localStorage.getItem('access_token');
const user_role = localStorage.getItem('role');

const WithLoginHeader = (props: any) => {
  return (
    <Layout type="loggedin" className="">
      {props.children}
    </Layout>
  )
}



const App = () => {
  return (<Suspense fallback={<div />}>
    <Provider store={store}>
      <Router>
        {access_token ? (
          <Switch>

            <Route exact={true} path="/" component={() => <WithLoginHeader>{<Home />}</WithLoginHeader>} />
            <Route exact={true} path="/home" component={() => <WithLoginHeader>{<Home />}</WithLoginHeader>} />
            <Route exact={true} path="/customer" component={() => <WithLoginHeader>{<Customer />}</WithLoginHeader>} />
            <Route exact={true} path="/provider" component={() => <WithLoginHeader>{<ProviderManagement />}</WithLoginHeader>} />
            <Route exact={true} path="/userprofile/:type/:id" component={() => <WithLoginHeader>{<UserProfile />}</WithLoginHeader>} />
            <Route exact={true} path="/requestprofile/:id" component={() => <WithLoginHeader>{<RequestProfile />}</WithLoginHeader>} />
            <Route exact={true} path="/payments" component={() => <WithLoginHeader>{<PaymentManagement />}</WithLoginHeader>} />
            <Route exact={true} path="/jobs/:id/:type/:name" component={() => <WithLoginHeader>{<Jobs />}</WithLoginHeader>} />
            <Route exact={true} path="/jobsmanagement" component={() => <WithLoginHeader>{<JobsManagement />}</WithLoginHeader>} />
            <Route exact={true} path="/rating" component={() => <WithLoginHeader>{<Rating />}</WithLoginHeader>} />
            <Route exact={true} path="/admin" component={() => <WithLoginHeader>{<Adminprofile />}</WithLoginHeader>} />
            <Route exact={true} path="/editadmin" component={() => <WithLoginHeader>{<EditAdmin />}</WithLoginHeader>} />
            <Route exact={true} path="/changepassword" component={() => <WithLoginHeader>{<ChangePassword />}</WithLoginHeader>} />
            <Route exact={true} path="/expiry" component={() => <WithLoginHeader>{<Expiry />}</WithLoginHeader>} />
            <Route exact={true} path="/commision" component={() => <WithLoginHeader>{<Commision />}</WithLoginHeader>} />
            <Route exact={true} path="/contact" component={() => <WithLoginHeader>{<Contact />}</WithLoginHeader>} />
            <Route exact={true} path="/jobdetail/:id" component={() => <WithLoginHeader>{<JobDetail />}</WithLoginHeader>} />

            <Route exact={true} path="/categories" component={() => <WithLoginHeader>{<Category />}</WithLoginHeader>} />
            <Route exact={true} path="/categories/:id/:name" component={() => <WithLoginHeader>{<SubCategory />}</WithLoginHeader>} />
            <Route exact={true} path="/privacy-policy" component={() => <WithLoginHeader>{<PrivacyPolicy />}</WithLoginHeader>} />
            <Route exact={true} path="/terms-conditions" component={() => <WithLoginHeader>{<TermsConditions />}</WithLoginHeader>} />
            <Route exact={true} path="/home-content" component={() => <WithLoginHeader>{<HomeContent />}</WithLoginHeader>} />
            <Route exact={true} path="/contact-info" component={() => <WithLoginHeader>{<ContactInfo />}</WithLoginHeader>} />
            <Route exact={true} path="/how-it-works" component={() => <WithLoginHeader>{<HowItWorks />}</WithLoginHeader>} />
            <Route exact={true} path="/home-images" component={() => <WithLoginHeader>{<HomeImages />}</WithLoginHeader>} />
            <Route exact={true} path="/report" component={() => <WithLoginHeader>{<ReportPage />}</WithLoginHeader>} />

          </Switch>
        )
          : (
            <Switch>
              <Route exact={true} path="/" component={() => <Login />} />
            </Switch>
          )}

      </Router>
    </Provider>
  </Suspense>
  );
};

export default App;
