import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
// Importing Images
import profile_image from '../assets/images/profile_image.jpg';
import { userService } from '../_services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";


const Expiry = (props: any) => {
    const { t, i18n } = useTranslation();
    const [jobExpiry, setjobExpiry] = useState('');
    const [bidExpiry, setbidExpiry] = useState('');

    useEffect(() =>  callExpiryAPI(), []);

    function updateExpiry() {
        let regx = /^[0-9\b]+$/;
        if (!jobExpiry) {
            alert(t("Please enter expiry days for job"))
        }
        else if (!bidExpiry) {
            alert(t("Please enter expiry days for bid"))
        }
        else if (!regx.test(jobExpiry)) {
            alert(t("Job expiry days should include only numbers"))
        }
        else if (!regx.test(bidExpiry)) {
            alert(t("Bid expiry days should include only numbers"))
        }
        else if (parseInt(jobExpiry)<7) {
            alert(t("Job expiry days should not less than 7 days"))
        }
        else if (parseInt(bidExpiry)<7) {
            alert(t("Bid expiry days should not less than 7 days"))
        }
        else {
            var params = {
                job_expiry: jobExpiry,
                bid_expiry: bidExpiry
            }
            console.log("paramss",params)
            userService.updateExpiry(params)
                .then(response => {
                    console.log("response.user", response)
                    toast.success(t("Updated Successfuly"))
                    callExpiryAPI();
                })
                .catch(error => {
                    console.log(error.response)
                });

        }

    }

    function callExpiryAPI() {
        userService.expiry()
            .then(response => {
                console.log(response.data)
                setjobExpiry(response.data.expiry.job_expiry)
                setbidExpiry(response.data.expiry.bid_expiry)
            })
            .catch(error => {
                console.log(error.response)
            });
    }

    
    function onojobChanged(event) {
        setjobExpiry(jobExpiry.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    function onbidChanged(event) {
        setbidExpiry(bidExpiry.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }
   
    const { error, handleSubmit, pristine, reset, submitting } = props
    return (
        <div className="tables_area">
            <h2>{t("Expiry Management")}</h2>
            <div className="white_box my_profile">
                <div className="">
                   
                    <aside className="padding_20">
                        <div className="row">
                            <aside className="col-sm-6">
                                <label>{t("Job Expiry (In days)")}</label>
                                <input value={jobExpiry} onChange={onojobChanged} className="form-control" type="Job Expiry (In days)" />
                            </aside>
                          
                        </div>
                        <div className="row">
                            <aside className="col-sm-6">
                                <label>{t("Bid Expiry (In days)")}</label>
                                <input value={bidExpiry} onChange={onbidChanged}  className="form-control" type="Bid Expiry (In days" />
                            </aside>
                           
                        </div>
                        
                        <button onClick={() => updateExpiry()} className="blue_btn yellow_btn text-uppercase">{t("Update")}</button>
                    </aside>
                </div>
            </div>
            <ToastContainer />
        </div>

    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(Expiry)