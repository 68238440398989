import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'

import { userService } from '../_services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReadMoreAndLess from 'react-read-more-less';
import { useTranslation } from "react-i18next";
import { DummyImage } from '../config/config';
import moment from 'moment';



const Customer = (props: any) => {
    const { t, i18n } = useTranslation();
    const [customersList, setcustomersList] = useState([{ _id: "", name: "", last_name: "", email: "", profile_image: "", birthdate: "", location: "", status: "", createdAt: "", jobCompleted: "" }]);
    const [fullData, setfullData] = useState([])
    const [backupData, setbackupData] = useState([{ _id: "", name: "", last_name: "", email: "", profile_image: "", birthdate: "", location: "", status: "", createdAt: "", jobCompleted: "" }])
    const [perPage, setperPage] = useState(5)
    const [currentPage, setcurrentPage] = useState(1)
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isLoading, setisLoading] = useState(1);
    const [search, setsearch] = useState('');

    // useEffect(() => callCustomersAPI(), []);
    useEffect(() => {
        callCustomersAPI(startDate, endDate);
    }, []);

    // const dateFormatter = (cell, row) => {
    //     return moment(cell).format('MM/DD/YYYY, hh:mm A');
    // };
    const dateFormatter = (cell, row) => {
        return (
            <p className="white-space-nowrap">
                {moment(cell).format('MM/DD/YYYY, hh:mm A')}
            </p>
        );
    };
    

    const columns = [{
        dataField: 'photo',
        text: t('Photo'),
        formatter: photoFormatter
    }, {
        dataField: 'name',
        text: t('First Name'),
        sort: true
    },
    {
        dataField: 'last_name',
        text: t('Last Name'),
        sort: true
    }, {
        dataField: 'email',
        text: t('Email'),
        sort: true
    },
    {
        dataField: 'createdAt',
        text: t('Joining Date'),
        sort: true,
        // formatter: dateFormatter,
        formatter: (cell, row) => (
            <>
                {moment(cell).format('MM/DD/YYYY, hh:mm A')}
                </>
        ),
        classes: 'white-space-nowrap'
        //  headerClasses: 'white-space-nowra'
    }, {
        dataField: 'jobCompleted',
        text: t('Jobs Done'),
        sort: true
    }, {
        dataField: 'jobs',
        text: t('Jobs'),
        formatter: jobsFormatter,
    }, {
        dataField: 'account_status',
        text: t('Account Status'),
        formatter: statusFormatter,
    }, {
        dataField: 'action',
        text: t('Action'),
        sort: true,
        formatter: actionFormatter,
        sortValue: (cell, row) => row.status
    }, {
        dataField: 'view',
        text: t('View'),
        formatter: viewFormatter
    }];



    function photoFormatter(cellContent, row, rowIndex) {
        return (
            <>
                <img src={row.profile_image} alt="" className="img-circle user_image" onError={(event) => ((event.target as HTMLImageElement).src = DummyImage)} />
            </>
        );
    }

    function jobsFormatter(cellContent, row, rowIndex) {
        return (
            <>
                <a onClick={() => goToJobs(row)} className="view_jobs">{t("View jobs")}</a>
            </>
        );
    }

    function actionFormatter(cellContent, row, rowIndex) {
        return (
            <>
                {row.status == 'ACT' ?
                    <button onClick={() => suspendUser(row._id, 'SUS')} className="suspend_button">{t("Suspend")}</button>
                    :
                    <button onClick={() => suspendUser(row._id, 'ACT')} className="active_button">{t("Activate")}</button>
                }
            </>
        );
    }

    function viewFormatter(cellContent, row, rowIndex) {
        return (
            <>
                <a onClick={() => goToProfile(row)} className="action_btn"><i className="fa fa-eye" aria-hidden="true"></i></a>

            </>
        );
    }

    function statusFormatter(cellContent, row, rowIndex) {
        return (
            <>
                {cellContent == "A" ?
                    <span style={{ color: 'green' }}>{t("Active")}</span>
                    :
                    <span style={{ color: 'red' }}>{t("Deleted")}</span>
                }
            </>
        );
    }

    function locationFormatter(cellContent, row, rowIndex) {
        return (
            <>
                <ReadMoreAndLess
                    className="read-more-content"
                    charLimit={50}
                    readMoreText="Read more"
                    readLessText="Read less"
                >
                    {row.commune}{row.commune ? "," : null} {row.appartment}{row.appartment ? "," : null} {row.location}, {row.city}, {row.state}, {row.country}{row.zip_code ? "," : null} {row.zip_code}
                </ReadMoreAndLess>
            </>
        );
    }




    function callCustomersAPI(startDate: string, endDate: string) {
        const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
        const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';
        const params = { startDate: startDate, endDate: endDate };
        if (formattedStartDate) params.startDate = formattedStartDate;
        if (formattedEndDate) params.endDate = formattedEndDate;
        userService.customers(params)
            .then(response => {
                console.log(response)
                var data = response.data.data;
                var newdata = [] as any;
                for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                    if (data[i]) {
                        console.log(data[i])
                        newdata.push(data[i]);
                    }
                }
                setbackupData(data);
                setcustomersList(data)
                setfullData(data);
                setcurrentPage(1)
                setisLoading(2)
            })
            .catch(error => {
                console.log(error.response)
            });
    }

    function suspendUser(id, status) {
        let result;
        if (status == "SUS") {
            result = window.confirm(t("Are you sure you want to suspend this user's account?"))
        }
        else {
            result = window.confirm(t("Are you sure you want to activate this user's account?"))
        }

        if (result) {
            setisLoading(1)
            var params = {
                user_id: id,
                status: status
            }
            userService.changeStatus(params)
                .then(response => {
                    console.log("response.user", response)
                    setisLoading(2)
                    setTimeout(
                        () => {
                            toast.success(status == "SUS" ? t("Suspended successfully") : t("Activated Successfully"))
                        },
                        500
                    );
                    callCustomersAPI(startDate, endDate);
                })
                .catch(error => {
                    console.log(error.response)
                });
        }
    }

    function goToProfile(item) {
        window.location.href = '/userprofile/C/' + item._id;
    }

    function goToJobs(item) {
        window.location.href = '/jobs/' + item._id + '/customer/' + item.name;
    }

    function onSearchChanged(event) {
        setsearch(search.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        if (search.length > 1) {
            var searchData = [] as any;
            for (var j in backupData) {
                var fullname = backupData[j].name + " " + backupData[j].last_name;
                if (backupData[j].name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupData[j].last_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    fullname.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupData[j].email.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupData[j].location.toLowerCase().includes(event.target.value.toLowerCase())) {
                    searchData.push(backupData[j]);
                }
            }
            var data = searchData;
            var newdata = [] as any;
            for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                if (data[i]) {
                    console.log(data[i])
                    newdata.push(data[i]);
                }
            }
            setcustomersList(newdata)
            setfullData(data);
            setcurrentPage(1)
        }
        else {
            setcustomersList(backupData)
        }
    }


    function handlePageChange(pageNumber) {
        console.log(pageNumber)
        var newdata = [] as any;
        for (var i = ((pageNumber - 1) * perPage); i < (((pageNumber - 1) * perPage) + perPage); i++) {
            if (fullData[i]) {
                console.log(fullData[i])
                newdata.push(fullData[i]);
            }
        }
        setcurrentPage(pageNumber)
        setcustomersList(newdata)
    }

    const resetDates = () => {
        callCustomersAPI("", "");
        setStartDate('');
        setEndDate('');
    };

    const { error, handleSubmit, pristine, reset, submitting } = props
    return (
        <div className="tables_area">
            {isLoading == 1 &&
                <div className="loader-back">
                    <div className="loaderrrr">
                        <span>
                            <img src={require("../assets/images/loader.gif")} alt="" />
                        </span>

                    </div>
                </div>
            }
            <div>


                <h2 className="pull-left">{t("Customers")}</h2>
                <div className="input-group search_right pull-right">
                    <input value={search} onChange={onSearchChanged} className="form-control" placeholder={t("Search")} />

                </div>
                <div className="filter_date">
                    <div>
                        <label>Start Date</label>
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            placeholder={t("Start Date")}
                            className="form-control"
                        />
                    </div>
                    <div>
                        <label>End Date</label>
                        <input
                            type="date"
                            value={endDate}
                            min={startDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            placeholder={t("End Date")}
                            className="form-control"
                        />
                    </div>
                    {startDate && (
                                <>
                                    <button onClick={() => callCustomersAPI(startDate, endDate)} className="btn btn-primary">
                                        Apply
                                    </button>
                                    <button onClick={resetDates} className="btn btn-secondary">
                                        Reset
                                    </button>
                                </>
                            )}
                </div>

                <div className="clear"></div>

                <div className="white_box">

                    <div className="table-responsive inner-main-table">

                        <div className="pagination_box">
                            <BootstrapTable keyField='id' data={customersList} columns={columns} pagination={paginationFactory({ sizePerPage: 5 })} />
                        </div>


                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(Customer)