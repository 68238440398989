import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
// Importing Images
import profile_image from '../assets/images/profile_image.jpg';
import { userService } from '../_services';
import Pagination from "react-js-pagination";
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReadMoreAndLess from 'react-read-more-less';
import Swal from 'sweetalert2';
import {
    BrowserRouter as Router,
    Route,
    Link,
    useParams
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from 'moment';

const customStyles1 = {
    overlay: {
        display: "block",
        paddingRight: "15px",
        backgroundColor: 'rgba(51,51,51,0.8)',
        zIndex: 99
    },

    content: {
        position: "",
        top: "",
        right: "",
        bottom: "",
        left: ""
    }
};


const Contact = (props: any) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const { t, i18n } = useTranslation();
    const [jobsList, setjobsList] = useState([{ _id: "", name: "", email: "", message: "", subject: "", status: "", user_id: { user_type: "" }, createdAt: "", reply_date: "" }]);
    const [contactProviderList, setContactProviderList] = useState([{ _id: "", name: "", email: "", message: "", subject: "", status: "", user_id: { user_type: "" }, createdAt: "", reply_date: "" }]);
    const [unrepliedCustomer, setUnrepliedCustomer] = useState(0)
    const [unrepliedProvider, setUnrepliedProvider] = useState(0)
    const [backupjobsList, setbackupjobsList] = useState([{ name: "", email: "", message: "", subject: "", status: "", user_id: { user_type: "" }, createdAt: "", reply_date: "" }])
    const [backupcontactProviderList, setbackupcontactProviderList] = useState([{ name: "", email: "", message: "", subject: "", status: "", user_id: { user_type: "" }, createdAt: "", reply_date: "" }])


    const [fullData, setfullData] = useState([])
    const [perPage, setperPage] = useState(10)
    const [currentPage, setcurrentPage] = useState(1)
    const [editModal, seteditModal] = useState(false);
    const [message, setmessage] = useState('')
    const [selectedId, setselectedId] = useState('');
    const [selectedEmail, setselectedEmail] = useState('');
    const [selectedName, setselectedName] = useState('');
    const [isLoading, setisLoading] = useState(1);
    const [fullData2, setfullData2] = useState([])
    const [perPage2, setperPage2] = useState(10)
    const [currentPage2, setcurrentPage2] = useState(1)

    const [search, setsearch] = useState('');
    const [search2, setsearch2] = useState('');


    useEffect(() => {
        callHelpAPI("", "")
    }, [])

    function callHelpAPI(startDate: string, endDate: string) {
        const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
        const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';
        const params = { startDate: startDate, endDate: endDate };
        if (formattedStartDate) params.startDate = formattedStartDate;
        if (formattedEndDate) params.endDate = formattedEndDate;
        userService.contacts(params)
            .then(response => {
                console.log("response.data.data", response.data.data)
                var data = response.data.data;
                var newdata = [] as any;
                var unreplied_customer = 0;

                for (var i = (0 * data.length); i < ((0 * data.length) + data.length); i++) {
                    if (data[i] && data[i]?.user_id?.user_type == 'C') {
                        console.log("cccccc", data[i])
                        newdata.push(data[i]);
                    }
                    if (data[i] && data[i].user_id.user_type == 'C' && data[i].status == "A") {
                        console.log("gdsddggfg");
                        unreplied_customer += 1
                    }
                }
                setjobsList(newdata)
                setbackupjobsList(data)
                setfullData(newdata);
                customPaginate(1, newdata)
                setUnrepliedCustomer(unreplied_customer)
                var newdata2 = [] as any;
                var unreplied_provider = 0;
                for (var i = (0 * data.length); i < ((0 * data.length) + data.length); i++) {
                    if (data[i] && data[i]?.user_id?.user_type == 'P') {
                        newdata2.push(data[i]);
                    }
                    if (data[i] && data[i]?.user_id?.user_type == 'P' && data[i]?.status == "A") {
                        unreplied_provider += 1
                    }
                }
                setContactProviderList(newdata2)
                setbackupcontactProviderList(data)
                setfullData2(newdata2);
                customPaginateProvider(1, newdata2)
                setUnrepliedProvider(unreplied_provider)
                setisLoading(2)
            })
            .catch(error => {
                console.log(error.response)
            });
    }

    function closeModal() {
        setmessage('')
        seteditModal(false)
    }

    function onPriceChanged(event) {
        setmessage(message.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    function replyToUser(item) {
        setselectedId(item._id)
        setselectedEmail(item.email)
        setselectedName(item.name)
        seteditModal(true)
    }


    function doReplyToUser() {
        setisLoading(1)
        var params = {
            help_id: selectedId,
            message: message,
            email: selectedEmail,
            name: selectedName
        }
        userService.replyToUser(params)
            .then(response => {
                console.log("response.user", response)
                toast.success(t("Replied successfully."));
                closeModal();
                callHelpAPI(startDate, endDate);
            })
            .catch(error => {
                console.log(error.response)
            });
    }

    function customPaginate(pageNumber, list = fullData, itemcount = perPage) {
        let tempList = [...list];
        setcurrentPage(pageNumber);
        let page = ((pageNumber - 1) * itemcount)
        let mainList = tempList.splice(page, itemcount);
        setjobsList(mainList);
    }

    function customPaginateProvider(pageNumber, list = fullData2, itemcount = perPage) {
        let tempList = [...list];
        setcurrentPage2(pageNumber);
        let page = ((pageNumber - 1) * itemcount)
        let mainList = tempList.splice(page, itemcount);
        setContactProviderList(mainList);
    }

    function onSearchChanged(event) {
        setsearch(search.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        if (search.length) {
            var searchData = [] as any;
            for (var j in backupjobsList) {
                if (backupjobsList[j].name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupjobsList[j].email.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupjobsList[j].subject.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupjobsList[j].message.toLowerCase().includes(event.target.value.toLowerCase())) {
                    searchData.push(backupjobsList[j]);
                }
            }
            var data = searchData;
            var newdata = [] as any;
            for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                if (data[i]) {
                    newdata.push(data[i]);
                }
            }
            setjobsList(newdata)
            setfullData(data);
            setcurrentPage(1)
        }
    }


    function onSearchChanged2(event) {
        setsearch2(search2.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        if (search2.length) {
            var searchData = [] as any;
            for (var j in backupcontactProviderList) {
                if (backupcontactProviderList[j].name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupcontactProviderList[j].email.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupcontactProviderList[j].subject.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    backupcontactProviderList[j].message.toLowerCase().includes(event.target.value.toLowerCase())) {
                    searchData.push(backupcontactProviderList[j]);
                }
            }
            var data = searchData;
            var newdata = [] as any;
            for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                if (data[i]) {
                    newdata.push(data[i]);
                }
            }
            setContactProviderList(newdata)
            setfullData2(data);
            setcurrentPage2(1)
        }
    }

    async function repliedMessage(id) {
        try {
            const response = await userService.getRepliedMessage(id)

            if (response?.status == 200) {
                Swal.fire({
                    title: 'Replied message',
                    text: response?.data?.data?.replyMessage,
                    icon: 'info',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: "replied-popup"
                    }
                });
            }

        } catch (error) {
        }
    }
    const resetDates = () => {
        callHelpAPI("", "")
        setStartDate('');
        setEndDate('');
    };

    const { error, handleSubmit, pristine, reset, submitting } = props
    return (
        <section className="my_job_tab_box">
            {isLoading == 1 &&
                <div className="loader-back">
                    <div className="loaderrrr">
                        <span>
                            <img src={require("../assets/images/loader.gif")} alt="" />
                        </span>

                    </div>
                </div>
            }
            <div className="tab_heading_content">
                <ul className="nav nav-tabs">
                    <li className="active active_list"><a data-toggle="tab" href="#escrowed">{t("Customer")} <span>{unrepliedCustomer}</span></a></li>
                    <li className="past-list"><a data-toggle="tab" href="#paid">{t("Provider")} <span>{unrepliedProvider}</span></a></li>
                </ul>
            </div>
            <div className="tab-content">
                <div id="escrowed" className="tab-pane fade in active">
                    <div className="tables_area">
                        <h2 className="pull-left">{t("Contact Management")}</h2>
                        <div className="input-group search_right pull-right">
                            <input value={search} onChange={onSearchChanged} className="form-control" placeholder={t("Search")} />
                        </div>
                        <div className="filter_date">
                            <div>
                                <label>Start Date</label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    placeholder={t("Start Date")}
                                    className="form-control"
                                />
                            </div>
                            <div>
                                <label>End Date</label>
                                <input
                                    type="date"
                                    value={endDate}
                                    min={startDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    placeholder={t("End Date")}
                                    className="form-control"
                                />
                            </div>
                            {startDate && (
                                <>
                                    <button onClick={() => callHelpAPI(startDate, endDate)} className="btn btn-primary">
                                        Apply
                                    </button>
                                    <button onClick={resetDates} className="btn btn-secondary">
                                        Reset
                                    </button>
                                </>
                            )}
                            
                        </div>
                        <div className="clear"></div>
                        <div className="white_box">
                            <div className="table-responsive">

                                <table style={{ width: '100%' }} >
                                    <thead>
                                        <tr>
                                            <th>{t("Name")}</th>
                                            <th>{t("Email")}</th>
                                            <th>{t("Subject")}</th>
                                            <th>{t("Message")}</th>
                                            <th>{t("Message On")}</th>
                                            <th>{t("Action")}</th>
                                            <th>{t("Replied On")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {jobsList?.length && jobsList[0]?.name ?
                                            jobsList.map((item, index) =>
                                                <tr>
                                                    <td className={item.status == "A" ? "customer-message" : ""}>{item?.name}</td>
                                                    <td className={item.status == "A" ? "customer-message" : "white-space-nowrap"}>{item?.email}</td>
                                                    <td className={item.status == "A" ? "customer-message" : ""}>{item?.subject}</td>
                                                    <td className={item.status == "A" ? "customer-message" : ""}><ReadMoreAndLess
                                                        className="read-more-content"
                                                        charLimit={100}
                                                        readMoreText="Read more"
                                                        readLessText="Read less"
                                                    >
                                                        {item?.message}
                                                    </ReadMoreAndLess></td>
                                                    <td className={item.status == "A" ? "customer-message" : "white-space-nowrap"}>{moment(item.createdAt).format('MM/DD/YYYY, hh:mm A')}</td>
                                                    <td><button className={item?.status == "A" ? "mark_read_btn" : "mark_read_btn_green"} onClick={() => {

                                                        if (item?.status == "A") {
                                                            replyToUser(item);
                                                        } else {

                                                            repliedMessage(item._id)
                                                        }

                                                    }}>{item?.status == "A" ? t('Reply') : t('View Reply')}</button></td>
                                                    <td className={item.status == "A" ? "customer-message" : "white-space-nowrap"}>{item.status == "REP" ?
                                                        moment(item.reply_date).format('MM/DD/YYYY, hh:mm A')
                                                        : "--"}</td>
                                                </tr>
                                            )
                                            :
                                            isLoading != 1 ?
                                                <tr> <td colSpan={5}><p className="no_found text-center">{t("No data found")}</p></td></tr>
                                                : null}

                                    </tbody>
                                </table>


                                <Pagination
                                    activePage={currentPage}
                                    itemsCountPerPage={perPage}
                                    totalItemsCount={fullData.length}
                                    pageRangeDisplayed={5}
                                    onChange={(page) => customPaginate(page)}
                                />

                            </div>
                        </div>
                    </div>
                </div>


                <div id="paid" className="tab-pane fade">
                    <div className="tables_area">
                        <h2 className="pull-left">{t("Contact Management")}</h2>
                        <div className="input-group search_right pull-right">
                            <input value={search2} onChange={onSearchChanged2} className="form-control" placeholder={t("Search")} />
                        </div>
                        <div className="filter_date">
                            <div>
                                <label>Start Date</label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    placeholder={t("Start Date")}
                                    className="form-control"
                                />
                            </div>
                            <div>
                                <label>End Date</label>
                                <input
                                    type="date"
                                    value={endDate}
                                    min={startDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    placeholder={t("End Date")}
                                    className="form-control"
                                />
                            </div>
                            {startDate && (
                                <>
                                    <button onClick={() => callHelpAPI(startDate, endDate)} className="btn btn-primary">
                                        Apply
                                    </button>
                                    <button onClick={resetDates} className="btn btn-secondary">
                                        Reset
                                    </button>
                                </>
                            )}
                        </div>
                        <div className="clear"></div>
                        <div className="white_box">
                            <div className="table-responsive">


                                <table style={{ width: '100%' }} >
                                    <thead>
                                        <tr>
                                            <th>{t("Name")}</th>
                                            <th>{t("Email")}</th>
                                            <th>{t("Subject")}</th>
                                            <th>{t("Message")}</th>
                                            <th>{t("Message On")}</th>
                                            <th>{t("Action")}</th>
                                            <th>{t("Replied On")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contactProviderList?.length && contactProviderList[0]?.name ?
                                            contactProviderList.map((item, index) =>
                                                <tr>
                                                    <td className={item?.status == "A" ? "customer-message" : ""}>{item?.name}</td>
                                                    <td className={item?.status == "A" ? "customer-message" : "white-space-nowrap"}>{item?.email}</td>
                                                    <td className={item?.status == "A" ? "customer-message" : ""}>{item?.subject}</td>
                                                    <td className={item?.status == "A" ? "customer-message" : ""}><ReadMoreAndLess
                                                        className="read-more-content"
                                                        charLimit={100}
                                                        readMoreText="Read more"
                                                        readLessText="Read less"
                                                    >
                                                        {item.message}
                                                    </ReadMoreAndLess></td>
                                                    <td className={item.status == "A" ? "customer-message" : "white-space-nowrap"}>{moment(item.createdAt).format('MM/DD/YYYY, hh:mm A')}</td>
                                                    <td><button className={item?.status == "A" ? "mark_read_btn" : "mark_read_btn_green"} onClick={() => {
                                                        if (item?.status == "A") {
                                                            replyToUser(item)
                                                        } else {
                                                            repliedMessage(item._id)
                                                        }
                                                    }

                                                    }>{item?.status == "A" ? t('Reply') : t('View Reply')}</button></td>
                                                    <td className={item.status == "A" ? "customer-message" : "white-space-nowrap"}>{item.status == "REP" ?
                                                        moment(item.reply_date).format('MM/DD/YYYY, hh:mm A')
                                                        : "--"}</td>
                                                </tr>
                                            )
                                            :
                                            isLoading != 1 ?
                                                <tr> <td colSpan={5}><p className="no_found text-center">{t("No data found")}</p></td></tr>
                                                : null}


                                    </tbody>
                                </table>

                            </div>
                            <Pagination
                                activePage={currentPage2}
                                itemsCountPerPage={perPage2}
                                totalItemsCount={fullData2.length}
                                pageRangeDisplayed={5}
                                onChange={(page) => customPaginateProvider(page)}
                            // onChange={handlePageChange2}
                            />
                        </div>
                    </div>
                </div>



            </div>
            <Modal
                ariaHideApp={false}
                isOpen={editModal}
                onRequestClose={closeModal}
                className={
                    "modal-content  new_modal_content add_profile_modal-content"}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title"><span><i className="fa fa-times-circle-o" onClick={closeModal} aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                </div>
                <div className="modal-body">

                    <div className="row">
                        <aside className="col-sm-12">
                            <div className="form-group">
                                <textarea value={message} onChange={onPriceChanged} className="form-control" style={{ height: 150 }} placeholder={t("Enter Message")}></textarea>
                            </div>
                            <button onClick={() => doReplyToUser()} style={{ marginTop: 0 }} className="blue_btn yellow_btn text-uppercase">{t("Send")}</button>
                        </aside>
                    </div>

                </div>

            </Modal>

            <ToastContainer />
        </section>
    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(Contact)