import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { userService } from '../_services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";


const ContactInfo = (props: any) => {
    const { t, i18n } = useTranslation();
    
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');

    useEffect(() => getContactInfo(), []);


    function getContactInfo() {
        userService.contactInfo()
            .then(response => {
                console.log(response.data)
                setEmail(response.data.data.email)
                setAddress(response.data.data.address)
                setPhone(response.data.data.contact_number)
            })
            .catch(error => {
                console.log(error.response)
            });
    }


    function updateContactInfo() {
        if(!email){
            toast.error(t("Please enter email."))
        } 
        else if(!address){
            toast.error(t("Please enter address."))
        }
        else if(!phone){
            toast.error(t("Please enter phone."))
        }
        else{
            var params ={
                email: email,
                address: address,
                contact_number: phone
            }
            userService.updateContactInfo(params)
                .then(response => {
                    console.log("response", response)
                    toast.success(t("Contact info updated successfully."))
                    getContactInfo();
                })
                .catch(error => {
                    console.log(error.response)
            }); 
        }
    }
    

    function onEmailChanged(event) {
        setEmail(email.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }
    function onAddressChanged(event) {
        setAddress(address.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }
    function onPhoneChanged(event) {
        setPhone(phone.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    return (
       
        <section>
        <div className="container-fluid">
          <div className="row contact-info">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="page_header">
                <h2 className="subtitle">{t("Contact Information")}</h2>        
              </div>
              <div className="App">

                <div className="row">
                    <aside className="col-sm-12">
                        <div className="form-group">
                             <h4 className="subtitle">{t("Email")}</h4>
                             <input value={email} onChange={onEmailChanged} className="form-control" placeholder={t("Email")} />
                        </div>
                    </aside>
                </div>

                <div className="row">
                    <aside className="col-sm-12">
                        <div className="form-group">
                             <h4 className="subtitle">{t("Address")}</h4>
                             <input value={address} onChange={onAddressChanged} className="form-control" placeholder={t("Address")} />
                        </div>
                    </aside>
                </div>

                <div className="row">
                    <aside className="col-sm-12">
                        <div className="form-group">
                             <h4 className="subtitle">{t("Phone number")}</h4>
                             <input value={phone} onChange={onPhoneChanged} className="form-control" placeholder={t("Phone number")} />
                        </div>
                    </aside>
                </div>

              </div>
              <button className="save-bt sumbit-btn-bottom" onClick={updateContactInfo}>{t("Submit")}</button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>


       
    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(ContactInfo)