import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { userService } from '../_services';
import Pagination from "react-js-pagination";
import {
    BrowserRouter as Router,
    useParams
} from "react-router-dom";
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";

const customStyles1 = {
    overlay: {
        display: "block",
        paddingRight: "15px",
        backgroundColor: 'rgba(51,51,51,0.8)',
        zIndex: 99
    },

    content: {
        position: "",
        top: "",
        right: "",
        bottom: "",
        left: ""
    }
};

const SubCategory = (props: any) => {
    const { t, i18n } = useTranslation();
    const [subCategoryList, setSubCategoryList] = useState([{ _id: "", name: "",status:""}]);
    const [backupData, setbackupData] = useState([{ _id: "", name: "",status:""}]);
    const [fullData, setfullData] = useState([])
    const [perPage, setperPage] = useState(10)
    const [currentPage, setcurrentPage] = useState(1)
    const [isLoading, setisLoading] = useState(1);
    const [search, setsearch] = useState('');
    const [editModal, seteditModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({_id:""});
    const [catName, setCatName] = useState("");
    const [language, setLanguage] = useState(localStorage.getItem("selectLanguage")=="ENGLISH" ? "en": "fr");

    let { id, name } = useParams();

    useEffect(() => getSubCategoryList(language), []);

    useEffect(() => {
        var lang = localStorage.getItem("selectLanguage")=="ENGLISH" ? "en": "fr"
        setLanguage(lang)
        getSubCategoryList(lang)
    }, [localStorage.getItem("selectLanguage")]);


    function getSubCategoryList(language) {
        setLanguage(language)
        userService.subCategories(id, language)
            .then(response => {
                console.log(response)
                var data = response.data.data;
                var newdata = [] as any;
                for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                    if (data[i]) {
                        console.log(data[i])
                        newdata.push(data[i]);
                    }
                }
                setSubCategoryList(newdata)
                setfullData(data);
                setbackupData(data)
                setisLoading(2)
            })
            .catch(error => {
                console.log(error.response)
            });
    }




    function handlePageChange(pageNumber) {
        console.log(pageNumber)
        var newdata = [] as any;
        for (var i = ((pageNumber - 1) * perPage); i < (((pageNumber - 1) * perPage) + perPage); i++) {
            if (fullData[i]) {
                console.log(fullData[i])
                newdata.push(fullData[i]);
            }
        }
        setcurrentPage(pageNumber)
        setSubCategoryList(newdata)
    }

    function onSearchChanged(event) {
        setsearch(search.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        if (search.length) {
            var searchData = [] as any;
            for (var j in backupData) {
                if (backupData[j].name.toLowerCase().includes(event.target.value.toLowerCase())) {
                    searchData.push(backupData[j]);
                }
            }
            var data = searchData;
            var newdata = [] as any;
            for (var i = (0 * perPage); i < ((0 * perPage) + perPage); i++) {
                if (data[i]) {
                    console.log(data[i])
                    newdata.push(data[i]);
                }
            }
            setSubCategoryList(newdata)
            setfullData(data);
            setcurrentPage(1)
        }
    }


    function editSubCategory(item) {
        setSelectedCategory(item)
        setCatName(item.name)
        seteditModal(true)
    }

    function addSubCategory() {
        setSelectedCategory({_id:""})
        setCatName("")
        seteditModal(true)
    }


    function closeModal() {
        seteditModal(false)
    }

    
    function onNameChanged(event) {
        setCatName(catName && catName.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    
    function updateCategory() {
        if (!catName) {
            toast.error(t("Please enter sub-category name."))
        }
        else {
            var params = {
                name: catName
            }
            userService.updateSubCategory(params, selectedCategory._id, language)
                .then(response => {
                    console.log("response", response)
                    getSubCategoryList(language);
                    toast.success(t("Sub category updated successfully."))
                    seteditModal(false)
                })
                .catch(error => {
                    console.log(error.response)
                });
        }
    }

    function addCategory() {
        if (!catName) {
            toast.error(t("Please enter sub-category name."))
        }
        else {
            var params = {
                category_id: id,
                name: catName
            }
            userService.addSubCategory(params, language)
                .then(response => {
                    console.log("response", response)
                    getSubCategoryList(language);
                    toast.success(t("Sub category added successfully."))
                    seteditModal(false)
                })
                .catch(error => {
                    console.log(error.response)
                });
        }
    }


    return (
        <div className="tables_area">
            {isLoading == 1 &&
                <div className="loader-back">
                    <div className="loaderrrr">
                        <span>
                            <img src={require("../assets/images/loader.gif")} alt="" />
                        </span>

                    </div>
                </div>
            }
            <h2 className="pull-left">{t("Sub-Categories of")} {name}</h2>
            <div className="input-group search_right pull-right category-search-top-right">
                    {/* <select
                        className="form-select"
                        onChange={(e) => getSubCategoryList(e.target.value)}
                        value={language}
                    >
                        <option value="en">English</option>
                        <option value="fr">French</option>
                    </select> */}
                <input value={search} onChange={onSearchChanged} className="form-control" placeholder={t("Search")} />
                <button onClick={() => addSubCategory()} className="active_button">{t("Add Sub Category")}</button>
            </div>
            <div className="clear"></div>
            <div className="white_box">
                <div className="table-responsive">

                    <table style={{ width: '100%' }} >
                        <thead>
                            <tr>
                                <th>{t("Name")}</th>
                                <th>{t("Action")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {subCategoryList.length && subCategoryList[0].name ?
                                subCategoryList.map((item, index) =>
                                    <tr>
                                        <td>{item.name}</td>
                                        <td><a onClick={() => editSubCategory(item)} className="view_jobs">{t("Edit")}</a></td>
                                    </tr>
                                )
                                :
                                isLoading != 1 ?
                                <tr> <td colSpan={5}><p className="no_found text-center">{t("No data found")}</p></td></tr>
                                    : null}

                        </tbody>
                    </table>

                    <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={perPage}
                        totalItemsCount={fullData.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                </div>
            </div>


            <Modal
                ariaHideApp={false}
                isOpen={editModal}
                onRequestClose={closeModal}
                className={
                    "modal-content  new_modal_content add_profile_modal-content category-modal"}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title">{t("Edit")}<span><i className="fa fa-times-circle-o" onClick={closeModal} aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                </div>
                <div className="modal-body">

                    <div className="row">
                        <aside className="col-sm-12">
                            <div className="form-group">
                                <label>{t("Enter Name")}</label>
                            <input value={catName} onChange={onNameChanged} className="form-control" placeholder={t("Name")} />
                            </div>
                            <button onClick={()=>selectedCategory._id? updateCategory() : addCategory() } style={{ marginTop: "10px" }} className="blue_btn yellow_btn text-uppercase">{selectedCategory._id ? t("Update") : t("Add")}</button>
                        </aside>
                    </div>



                </div>

            </Modal>
            <ToastContainer />
        </div>
    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(SubCategory)