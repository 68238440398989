import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { userService } from '../_services';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useTranslation } from "react-i18next";


import {
    BrowserRouter as Router,
    Route,
    Link,
    useParams
} from "react-router-dom";



const JobsDetail = (props: any) => {
    const { t, i18n } = useTranslation();
    const [jobData, setjobData] = useState({ _id: "", attachment: "", title: "", user_id: { name: "", last_name: "", profile_image: "" }, hiredBidder: { name: "", last_name: "", profile_image: "" }, category: { name: "" }, complete_status: false, start_date: "", start_time: "", end_date: "", end_time: "", budget: "", finalPrice: "", location: "", description: "", job_images: [{ job_image: "" }], commune: "", city: "" });
    const [isLoading, setisLoading] = useState(1);

    let { id } = useParams();

    useEffect(() => callJobDetailAPI(), []);

    function callJobDetailAPI() {
        userService.jobDetail(id)
            .then(response => {
                console.log(response)
                setjobData(response.data.job)
                setisLoading(2)
            })
            .catch(error => {
                console.log(error.response)
                setisLoading(2)
            });
    }



    const { error, handleSubmit, pristine, reset, submitting } = props
    return (
        <div>
            {isLoading == 1 ?
                <div className="loader-back">
                    <div className="loaderrrr">
                        <span>
                            <img src={require("../assets/images/loader.gif")} alt="" />
                        </span>

                    </div>
                </div>
                :
                <div>
                    {jobData && jobData.job_images.length > 0 ?
                        <section className="preview_post_banner_area" style={{
                            backgroundImage: `url(${jobData.job_images[0].job_image})`
                        }} >
                        </section>
                        :
                        <section className="" ></section>
                    }

                    <section className="slider_preview_post">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-1">
                                </div>
                                <div className={jobData.job_images.length ? "col-md-4 slider-box" : "col-md-4 slider-box without_image"}>
                                    {jobData.job_images.length > 0 ?
                                        <Carousel autoPlay>
                                            {jobData.job_images.map((item, index) => {
                                                return (
                                                    <div>
                                                        <img src={item.job_image} alt="slider_image.png" style={{ width: '100%' }} />
                                                    </div>)
                                            })}
                                        </Carousel>
                                        :
                                        <div>
                                        </div>
                                    }
                                    <div className="all_bid_box">
                                        <div className="heading">
                                            <h1>{t("About Client")}  </h1>
                                        </div>
                                        <div className="all_bid_content">
                                            <ul>
                                                <li>
                                                    <div className="content-box job-detial-content-box new-job-details">
                                                        <div className="left-side-content job-detils-left-box">
                                                            <img src={jobData.user_id.profile_image} alt="bid_image" />
                                                            <h1>{jobData.user_id.name} {jobData.user_id.last_name}</h1>
                                                        </div>

                                                    </div>
                                                    <div className="clear"></div>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>

                                    {jobData.hiredBidder ?
                                        <div className="all_bid_box">
                                            <div className="heading">
                                                <h1>{t("About Provider")}  </h1>
                                            </div>
                                            <div className="all_bid_content">
                                                <ul>
                                                    <li>
                                                        <div className="content-box job-detial-content-box new-job-details">
                                                            <div className="left-side-content job-detils-left-box">
                                                                <img src={jobData.hiredBidder.profile_image} alt="bid_image" />
                                                                <h1>{jobData.hiredBidder.name} {jobData.hiredBidder.last_name}</h1>
                                                            </div>

                                                        </div>
                                                        <div className="clear"></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        :

                                        <div style={{ color: "#d66e52", marginLeft: 100 }}>
                                            <h4>{t("Not hired yet")}</h4>
                                        </div>
                                    }


                                </div>
                                <div className="col-md-6 slider_right_cont">
                                    <div className="slider_right_content">
                                        <h1>{jobData.title}</h1>
                                        <ul>
                                            <li>
                                                <p>{t("Service Category")}<span>{jobData.category.name}</span></p>
                                            </li>
                                            <li>
                                                <p>{t("Start Date & Time")}<span>{jobData.start_date} & {jobData.start_time}</span></p>
                                            </li>
                                            <li>
                                                <p>{t("End Date & Time")}<span>{jobData.end_date} & {jobData.end_time}</span></p>
                                            </li>
                                            <li>
                                                <p>{t("Budget")}<span>CFA {Number(jobData.finalPrice).toLocaleString('de-DE')}</span></p>
                                            </li>
                                            <li>
                                                <p>{t("Attachment")}<span>{jobData.attachment ? <a href={jobData.attachment} target="_black">{t("View document")}</a> : t('No document uploaded')}</span></p>
                                            </li>
                                            <li className="location">
                                                <p>{t("Location")}<span>{jobData.commune}{jobData.commune ? "," : null} {jobData.location}{jobData.city && jobData.city != "None" ? "," : null} {jobData.city != "None" ? jobData.city : null}</span> </p>
                                            </li>
                                            <li>
                                                <p className="description">{t("Description")}</p>
                                                <p className="description_content">{jobData.description}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                        </div>
                    </section>
                </div>}
        </div>
    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(JobsDetail)