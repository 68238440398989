export const config = {
  appurl: "https://admin.lookvast.com",
  apiUrl: "https://api.lookvast.com/api/v1/admins",
  apiurl:"https://api.lookvast.com/"
  // appurl: "http://192.168.2.39:3008",
  // apiUrl: "http://192.168.2.39:3008/api/v1/admins"

  

}


export const DummyImage = require("../assets/images/default_image.jpg")