import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
// Importing Images

import { userService } from '../_services';
import { Line } from 'react-chartjs-2';
import { useTranslation } from "react-i18next";



const Home = (props: any) => {
    const { t, i18n } = useTranslation();
    const [totalUsers, settotalUsers] = useState(0);
    const [customers, setcustomers] = useState(0);
    const [workers, setworkers] = useState(0);
    const [workersGraph, setworkersGraph] = useState([]);
    const [custGraph, setcustGraph] = useState([]);
    const [monthArray, setmonthArray] = useState([]);

    useEffect(() =>  callDashboardAPI(), []);

    function callDashboardAPI() {
        userService.dashboard()
            .then(response => {
                console.log(response)
                var data = response.data.data;
                settotalUsers(data.totalUsers);
                setcustomers(data.customers);
                setworkers(data.workers);
                setcustGraph(response.data.custarray);
                setworkersGraph(response.data.workerArray);
                setmonthArray(response.data.monthStringArray);
            })
            .catch(error => {
                console.log(error.response)
            });
    }

    const data = {
        labels: monthArray,
        datasets: [
          {
            label: t('Customers'),
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(255,119,80,10)',
            borderColor: 'rgba(255,119,80,10)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: custGraph
          },
          {
            label: t('Workers'),
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(35, 84, 99, 10)',
            borderColor: 'rgba(35, 84, 99, 10)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(35, 84, 99, 0.5)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(228, 5, 5, 0.5)',
            pointHoverBorderColor: 'rgba(228, 5, 5, 0.5)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: workersGraph
          }
        ]
      };
      


    const { error, handleSubmit, pristine, reset, submitting } = props
    return (
        <div>

            <div className="top3_area">
                <div className="row">
                    <aside className="col-lg-4">
                        <div className="white_three">
                            <span style={{ background: "#0cc2aa" }}><img src={require("../assets/images/user.png")} alt="" /></span>
                            <h3 style={{ color: "#0cc2aa" }}>{totalUsers}</h3>
                            <p className="text-uppercase">{t("TOTAL REGISTERED USERS")}</p>
                        </div>
                    </aside>
                    <aside className="col-lg-4">
                        <div className="white_three">
                            <span style={{ background: "rgba(255,119,80,10)" }}><img src={require("../assets/images/customer.png")} alt="" /></span>
                            <h3 style={{ color: "rgba(255,119,80,10)" }}>{customers}</h3>
                            <p className="text-uppercase">{t("Total REGISTERED Customers")}</p>
                        </div>
                    </aside>
                    <aside className="col-lg-4">
                        <div className="white_three">
                            <span style={{ background: "rgba(35, 84, 99, 10)" }}><img src={require("../assets/images/salon-user.png")} alt="" /></span>
                            <h3 style={{ color: "rgba(35, 84, 99, 10)" }}>{workers}</h3>
                            <p className="text-uppercase">{t("Total REGISTERED WORKERS")}</p>
                        </div>
                    </aside>
                </div>
            </div>

            <div className="row">
                <aside className="col-lg-12">
                    <div className="white_box total_sold">
                        <h4>{t("Customers and Workers Statics")}</h4>
                        <Line data={data} />
                    </div>
                </aside>

            </div>


        </div>



    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(Home)