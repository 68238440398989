
import React from 'react'
import { useTranslation } from "react-i18next";




const Sidebar = (props: any) => {
   const { t, i18n } = useTranslation();
   const currentRoute = window.location.pathname
   console.log(currentRoute)
   const { error, className, handleSubmit, pristine, reset, submitting } = props
   return (
      <div className="left_sidebar">
         <ul>
            <li className={currentRoute == "/home" || currentRoute == "/" ? "active" : ""} ><a href="/home"><i className="fa fa-tachometer" aria-hidden="true"></i><span>{t("Dashboard")}</span></a></li>
            <li className={currentRoute == "/customer" || currentRoute.includes("/userprofile/C") ? "active" : ""}><a href="/customer"><i className="fa fa-user-o" aria-hidden="true"></i><span>{t("Customer Management")}</span></a></li>
            <li className={currentRoute == "/provider" || currentRoute.includes("/userprofile/P") ? "active" : ""}><a href="/provider"><i className="fa fa-user-o" aria-hidden="true"></i><span>{t("Provider Management")}</span></a></li>
            <li className={currentRoute == "/payments" ? "active" : ""}><a href="/payments"><i className="fa fa-money" aria-hidden="true"></i><span>{t("Payment Management")}</span></a></li>
            <li className={currentRoute == "/jobsmanagement" ? "active" : ""}><a href="/jobsmanagement"><i className="fa fa-briefcase" aria-hidden="true"></i><span>{t("Jobs Management")}</span></a></li>
            <li className={currentRoute == "/categories" ? "active" : ""}><a href="/categories"><i className="fa fa-th-large" aria-hidden="true"></i><span>{t("Category Management")}</span></a></li>
            <li className={currentRoute == "/rating" ? "active" : ""}><a href="/rating"><i className="fa fa-star-o" aria-hidden="true"></i><span>{t("Rating & Review Management")}</span></a></li>
            <li className={currentRoute == "/report" ? "active" : ""}><a href="/report"><i className="fa fa-flag" aria-hidden="true"></i>
               <span>{t("Reporting Management")}</span></a></li>

            <li className={currentRoute == "/expiry" ? "active" : ""}><a href="/expiry"><i className="fa fa-hourglass-end" aria-hidden="true"></i><span>{t("Expiry Management")}</span></a></li>
            <li className={currentRoute == "/commision" ? "active" : ""}><a href="/commision"><i className="fa fa-percent" aria-hidden="true"></i><span>{t("Commission Management")}</span></a></li>
            <li className={currentRoute == "/contact" ? "active" : ""}><a href="/contact"><i className="fa fa-address-book-o" aria-hidden="true"></i><span>{t("Contact Management")}</span></a></li>
            <li className={""}>
               <a href="/privacy-policy" className='privacy-policy-option'>
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                  <span>{t("Content Management")}</span><i className="fa fa-angle-down" aria-hidden="true"></i>
               </a>

            </li>
            <div className='content-subcategory'>
               <ul>
                  <li className={currentRoute == "/privacy-policy" ? "active" : ""}><a href='/privacy-policy'>{t("Privacy Policy")}</a></li>
                  <li className={currentRoute == "/terms-conditions" ? "active" : ""}><a href='/terms-conditions'>{t("Terms & Conditions")}</a></li>
                  <li className={currentRoute == "/home-content" ? "active" : ""}><a href='/home-content'>{t("Home Content")}</a></li>
                  <li className={currentRoute == "/contact-info" ? "active" : ""}><a href='/contact-info'>{t("Contact Information")}</a></li>
                  <li className={currentRoute == "/how-it-works" ? "active" : ""}><a href='/how-it-works'>{t("How It Works")}</a></li>
                  <li className={currentRoute == "/home-images" ? "active" : ""}><a href='/home-images'>{t("Home Images")}</a></li>
               </ul>
            </div>
         </ul>
      </div>
   )
}

export default Sidebar

