import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
// Importing Images
import logo from '../assets/images/logo.png';
import shadow from '../assets/images/shadow.png';
import { userService } from '../_services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";



const Login = (props: any) => {
    const { t, i18n } = useTranslation();
    const [selectedValue, setselectedValue] = useState(1);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setisLoading] = useState(0);
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");



    const customStyles1 = {
        overlay: {
            display: "block",
            paddingRight: "15px",
            backgroundColor: 'rgba(51,51,51,0.8)',
            zIndex: 99
        },

        content: {
            position: "",
            top: "",
            right: "",
            bottom: "",
            left: ""
        }
    };

    const validateForm = (data: any) => {

        let fields = data;
        let errors: any = [];
        let formIsValid = true;
        formIsValid = validateEmail(data)
        if (password.length == 0) {
            setPasswordError(t("Password field can't be empty."))
            formIsValid = false
        }
        return formIsValid;
    }

    const submit = (values: any) => {
        if (validateForm(values)) {
            setisLoading(1)
            var params = {
                email: email,
                password: password
            }
            userService.login(params)
                .then(response => {
                    setisLoading(2)
                    toast.success(t("Login successfully."));
                    setTimeout(
                        () => {
                            console.log(response)
                            localStorage.setItem("access_token", "fghfgfjgfj")
                            window.location.href = '/home';
                        },
                        1000
                    );

                })
                .catch(error => {
                    setisLoading(2)
                    setTimeout(
                        () => {
                            console.log(error.response)
                            toast.error(error.response.data.message);
                        },
                        500
                    );
                    
                });

        }
    }

    const validateEmail = (data: any) => {
        let fields = data;
        let errors: any = [];
        let formIsValid = true;
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (email.length == 0) {
            setEmailError(t("Email address field can't be empty."))
            formIsValid = false
        } else if (!pattern.test(email)) {
            formIsValid = false;
            setEmailError(t('Email address is not valid.'))
        }
        return formIsValid
    }

    function onEmailChanged(event) {
        setEmailError("")
        setEmail(event.target.value.replace(/ /g, ""))
    }
    function onPasswordChanged(event) {
        setPasswordError("")
        setPassword(event.target.value.replace(/ /g, ""))
    }

    const { error, handleSubmit, pristine, reset, submitting } = props
    return (
        <div className="login_body">
            {isLoading == 1 &&
                <div className="loader-back">
                    <div className="loaderrrr">
                        <span>
                            <img src={require("../assets/images/loader.gif")} alt="" />
                        </span>

                    </div>
                </div>
            }
            <div className="container">
                <div className="logo_header text-center">
                    <a href="#"><img src={logo} alt="" /></a>
                </div>
                <div className="login_form_outer">
                    <div className="login_form">
                        <form onSubmit={handleSubmit((val: any) => submit(val))}>
                            <h2>{t("Sign In")}</h2>
                            <div className="form-group">
                                <label>{t("Email")}</label>
                                <input type="text" onChange={onEmailChanged} maxLength={40} className="form-control" placeholder="" value={email} />
                                {emailError.length > 0 && <span style={{ color: "red" }}>{emailError}</span>}
                            </div>
                            <div className="form-group">
                                <label>{t("Password")}</label>
                                <input type="password" maxLength={30} onChange={onPasswordChanged} className="form-control" placeholder="" value={password} />
                                {passwordError.length > 0 && <span style={{ color: "red" }}>{passwordError}</span>}
                            </div>

                            <button type="submit" disabled={submitting} className="blue_btn">{t("Sign In")}</button>
                        </form>
                    </div>
                    <img src={shadow} alt="" className="shadow" />
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(Login)