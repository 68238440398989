import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { userService } from '../_services';
import Pagination from "react-js-pagination";
import { config } from '../config/config'
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";

const customStyles1 = {
    overlay: {
        display: "block",
        paddingRight: "15px",
        backgroundColor: 'rgba(51,51,51,0.8)',
        zIndex: 99
    },

    content: {
        position: "",
        top: "",
        right: "",
        bottom: "",
        left: ""
    }
};

const HomeImages = (props: any) => {
    const { t, i18n } = useTranslation();
    const [homeImages, setHomeImages] = useState([{ _id: "", image: "", title:"", description:"", order:"", language:"", status:""}]);
    const [isLoading, setisLoading] = useState(1);
    const [editModal, seteditModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({_id:""});
    const [image, setImage] = useState("");
    const [uploadImage, setuploadImage] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [order, setOrder] = useState("");
    const [language, setLanguage] = useState(localStorage.getItem("selectLanguage")=="ENGLISH" ? "en": "fr");
    

    useEffect(() => getHomeImages(language), []);

    useEffect(() => {
        var lang = localStorage.getItem("selectLanguage")=="ENGLISH" ? "en": "fr"
        setLanguage(lang)
        getHomeImages(lang)
    }, [localStorage.getItem("selectLanguage")]);


    function getHomeImages(language) {
        setLanguage(language)
        userService.homeImages(language)
            .then(response => {
                console.log(response)
                setisLoading(2)
                setHomeImages(response.data.data)
            })
            .catch(error => {
                setisLoading(2)
                console.log(error.response)
            });
    }

    function editNewCategory(item) {
        setSelectedItem(item)
        setTitle(item.title)
        setDescription(item.description)
        setOrder(item.order)
        setImage(item.image)
        setuploadImage("")
        seteditModal(true)
    }

    function addNewCategory() {
        setSelectedItem({_id:""})
        setTitle("")
        setDescription("")
        setOrder("")
        setImage("")
        setuploadImage("")
        seteditModal(true)
    }

    function closeModal() {
        seteditModal(false)
    }

    function updateBackground() {
        if (!title) {
            toast.error(t("Please enter title."))
        }
        else if (!description) {
            toast.error(t("Please enter description."))
        }
        else {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('description', description);
            formData.append('order', order);
            if(uploadImage){
                formData.append('image', uploadImage); 
            }
            console.log("formData",formData)

            userService.updateHomeBackground(formData, selectedItem._id)
                .then(response => {
                    console.log("response", response)
                    getHomeImages(language);
                    toast.success(t("Home sceen updated successfully."))
                    seteditModal(false)
                })
                .catch(error => {
                    console.log(error.response)
                });
        }
    }

    function addBackground() {
        if (!uploadImage) {
            toast.error(t("Please select image."))
        }
        else if (!title) {
            toast.error(t("Please enter title."))
        }
        else if (!description) {
            toast.error(t("Please enter description."))
        }
        else {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('description', description);
            formData.append('order', String(homeImages.length+1));
            formData.append('language', language);
            formData.append('image', uploadImage); 

            userService.addHomeBackground(formData)
                .then(response => {
                    console.log("response", response)
                    getHomeImages(language);
                    toast.success(t("Home sceen image added successfully."))
                    seteditModal(false)
                })
                .catch(error => {
                    console.log(error.response)
                });
        }
    }

    function onTitleChanged(event) {
        setTitle(title.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    function onDescriptionChanged(event) {
        setDescription(description.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    function onImageChanged(event) {
        event.preventDefault();
        var file = event.target.files[0];
        setuploadImage(file);
        setImage(URL.createObjectURL(event.target.files[0]));
    }


    return (
        <div className="tables_area">
            {isLoading == 1 &&
                <div className="loader-back">
                    <div className="loaderrrr">
                        <span>
                            <img src={require("../assets/images/loader.gif")} alt="" />
                        </span>

                    </div>
                </div>
            }
            <h2 className="pull-left">{t("Home Screen Background Images")}</h2>
            <div className="input-group search_right pull-right privacy-policy">
                    {/* <select
                        className="form-select home-image-right-area"
                        onChange={(e) => getHomeImages(e.target.value)}
                        value={language}
                    >
                    <option value="en">English</option>
                    <option value="fr">French</option>
                </select> */}
                <button onClick={() => addNewCategory()} className="active_button">{t("Add Background")}</button>
            </div>
            <div className="clear"></div>
            <div className="white_box">
                <div className="table-responsive">

                    <table style={{ width: '100%' }} >
                        <thead>
                            <tr>
                                <th>{t("Photo")}</th>
                                <th>{t("Title")}</th>
                                <th>{t("Description")}</th>
                                <th>{t("Order")}</th>
                                <th>{t("Action")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {homeImages.length && homeImages[0].title ?
                                homeImages.map((item, index) =>
                                    <tr>
                                        <td><a href={item.image} target="_blank"> <img src={item.image} alt="" className="img-circle user_image" /></a></td>
                                        <td>{item.title}</td>
                                        <td>{item.description}</td>
                                        <td>{item.order}</td>
                                        <td><a onClick={() => editNewCategory(item)} className="view_jobs">{t("Edit")}</a></td>
                                    </tr>
                                )
                                :
                                isLoading != 1 ?
                                <tr> <td colSpan={5}><p className="no_found text-center">{t("No data found")}</p></td></tr>
                                    : null}

                        </tbody>
                    </table>

                  
                </div>
            </div>

            <Modal
                ariaHideApp={false}
                isOpen={editModal}
                onRequestClose={closeModal}
                className={
                    "modal-content  new_modal_content add_profile_modal-content category-modal"}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title">{t("Add Background")}<span><i className="fa fa-times-circle-o" onClick={closeModal} aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                </div>
                <div className="modal-body">

                    <div className="row">
                        <aside className="col-sm-12">
                        <div className='upload-area'>
                            <div className="upload_image">
                                <img src={image ? image : require("../assets/images/upload-image.png")} alt="" className="img-circle profile_image upload-picture" />
                                <input type="file" onChange={onImageChanged} />
                                </div>
                                </div>
                            <div className="form-group">
                                <label>{t("Enter Title")}</label>
                                <input value={title} onChange={onTitleChanged} className="form-control" placeholder={t("Title")} />
                            </div>
                            <div className="form-group">
                                <label>{t("Enter Description")}</label>
                                <input value={description} onChange={onDescriptionChanged} className="form-control" placeholder={t("Description")} />
                            </div>
                            {selectedItem._id ?
                                <div className="form-group qty-area">
                                    <label>{t("Order")}</label>
                                <select
                                    className="form-select"
                                    onChange={(e) => setOrder(e.target.value)}
                                    value={order}
                                >
                                    {homeImages.map((item, index) =>
                                        <option value={index+1}>{index+1}</option>
                                    )}
                                </select>
                            </div>
                            : null}
                            <button onClick={()=>selectedItem._id? updateBackground() : addBackground() } style={{ marginTop: "10px" }} className="blue_btn yellow_btn text-uppercase">{selectedItem._id ? t("Update") : t("Add")}</button>
                        </aside>
                    </div>

                </div>

            </Modal>
            <ToastContainer />

        </div>
    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(HomeImages)