import React, {useEffect, useState} from 'react';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import AuthContextProvider from '../../contexts/AuthContext';

import { Redirect } from 'react-router-dom';
import { userService } from '../../_services';



export const Layout = (props: any) => {
  const access_token = localStorage.getItem('access_token');
   if(props.type === '') {
    if(access_token===null || access_token===undefined){
         userService.logout();
		     return <Redirect to='/login' />
       }
      
      return (
        <AuthContextProvider>
              <Header/>
              {props.children}
              <Footer/>
        </AuthContextProvider>
      )
    }

    else if(props.type === 'loggedin'){
      return (
        <AuthContextProvider>
          <div className="main-page">
              <Header/>
              <div className="content_area">
              <Sidebar />
              {props.children}
              <Footer/>
              </div>
           </div>
        </AuthContextProvider>
      )
    }
    
    else {
      return (
        <div></div>

      )
    }
}



