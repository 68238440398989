import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
// Importing Images
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";

import { userService } from '../_services';

import {
    BrowserRouter as Router,
    Route,
    Link,
    useParams
} from "react-router-dom";



const customStyles1 = {
    overlay: {
        display: "block",
        paddingRight: "15px",
        backgroundColor: 'rgba(51,51,51,0.8)',
        zIndex: 99
    },

    content: {
        position: "",
        top: "",
        right: "",
        bottom: "",
        left: ""
    }
};


const RequestProfile = (props: any) => {

    const { t, i18n } = useTranslation();
    const [checked, setchecked] = useState(true);
    const [userprofile, setuserprofile] = useState({ name: "", email: "", profile_image: "", birthdate: "", location: "", bio: "", categories: "", subcategories: "", id_document: "", profile_document: "", documentsToShow: [{ document_url: "", name: "" }], city:"", state:"", country:"", appartment:"", zip_code:"", user_type:"",commune:"" });
    const [rejectReason, setrejectReason] = useState('');
    const [editModal, seteditModal] = useState(false);



    useEffect(() => callRequestProfileAPI(), []);

    let { id } = useParams();
    // setUserid(id)

    function callRequestProfileAPI() {
        userService.userprofile(id)
            .then(response => {
                console.log(response.data)
                var data = response.data.user;
                var categoriesnew = [];
                let documentsToShow: any[] = [];
                let subcategoriesnew: any[] = [];
                for (var i in data.categories) {
                    categoriesnew[i] = data.categories[i].name;
                    if (data.categories[i].document_url) {
                        documentsToShow.push(data.categories[i]);
                    }
                    for (var j in data.categories[i].sub_categories) {
                        subcategoriesnew.push(data.categories[i].sub_categories[j].name)
                    }
                }
                data.categories = categoriesnew.join(", ")
                data.subcategories = subcategoriesnew.join(", ")
                data.documentsToShow = documentsToShow;
                setuserprofile(data)
            })
            .catch(error => {
                console.log(error.response)
            });
    }

    function approveRej(status) {
        let result;
        if (status == "ACT") {
            result = window.confirm(t('Are you sure you want to approve this user?'))
        }
        else {
            seteditModal(true)
            // result = window.confirm('Are you sure you want to reject this user?')
        }

        if (result && status == "ACT") {
            var params = {
                user_id: id,
                status: status,
                rejectReason: ''
            }
            userService.changeStatus(params)
                .then(response => {
                    console.log("response.user", response)
                    window.location.href = '/provider';
                })
                .catch(error => {
                    console.log(error.response)
                });
        }
    }

    function rejectUser() {
        if (!rejectReason) {
            toast.error(t("Please enter reason to reject."))
        }
        else {
            var params = {
                user_id: id,
                status: 'REJ',
                rejectReason: rejectReason
            }
            userService.changeStatus(params)
                .then(response => {
                    console.log("response.user", response)
                    window.location.href = '/provider';
                })
                .catch(error => {
                    console.log(error.response)
                });
        }
    }

    function closeModal() {
        setrejectReason('')
        seteditModal(false)
    }

    function onRejectChanged(event) {
        setrejectReason(rejectReason.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    const { error, handleSubmit, pristine, reset, submitting } = props
    return (
        <div>
            <h2>{t("User Profile")}</h2>
            <div className="white_box my_profile">
                <div className="row">
                    <aside className="col-lg-4 text-center">
                        <img src={userprofile.profile_image} alt="" className="img-circle profile_image" />
                    </aside>
                    <aside className="col-lg-4">
                        <div className="row">
                            <aside className="col-sm-12">
                                <label>{t("Name")}</label>
                                <h4>{userprofile.name}</h4>
                            </aside>

                        </div>
                        {userprofile.categories.length ?
                            <div className="row">
                                <aside className="col-sm-12">
                                    <label>{t("Categories")}</label>
                                    <h4>{userprofile.categories}</h4>
                                </aside>
                            </div>
                            : null}

                        {/* <div className="row">
                            <aside className="col-sm-12">
                                <label>Date of Birth</label>
                                <h4>{userprofile.birthdate}</h4>
                            </aside>
                        </div> */}
                        <div className="row">
                            <aside className="col-sm-12">
                                <label>{t("Profile Document")}</label>
                                {userprofile.profile_document ?
                                    <h4><a href={userprofile.profile_document} target="_blank">{t("View document")}</a></h4>
                                    :
                                    <h4>{t("No document uploaded")}</h4>
                                }
                            </aside>

                        </div>
                        <div className="row">
                            <aside className="col-sm-12">
                                <label>{t("Address")}</label>
                                <h4>{userprofile.commune}{userprofile.commune? "," :null} {userprofile.appartment}{userprofile.appartment? "," :null} {userprofile.location}, {userprofile.city}, {userprofile.state}, {userprofile.country}{userprofile.zip_code? "," :null} {userprofile.zip_code}</h4>
                            </aside>


                        </div>

                        <div className="row">
                            <aside className="col-sm-12">
                                <a onClick={() => approveRej('ACT')} className="pink_button">{t("Approve")}</a>
                                <a onClick={() => approveRej('REJ')} className="pink_button pink_border">{t("Reject")}</a>
                            </aside>

                        </div>
                    </aside>



                    <aside className="col-lg-4">
                        <div className="row">
                            <aside className="col-sm-12">
                                <label>{t("Email Address")}</label>
                                <h4>{userprofile.email}</h4>
                            </aside>

                        </div>
                        {userprofile.categories.length ?
                            <div className="row">
                                <aside className="col-sm-12">
                                    <label>{t("Sub-Categories")}</label>
                                    <h4>{userprofile.subcategories}</h4>
                                </aside>
                            </div>
                            : null}

                        <div className="row">
                            <aside className="col-sm-12">
                                <label>{t("Id Document")}</label>
                                {userprofile.id_document ?
                                    <h4><a href={userprofile.id_document} target="_blank">{t("View document")}</a></h4>
                                    :
                                    <h4>{t("No document uploaded")}</h4>
                                }
                            </aside>
                        </div>
                        
                        <div className="row">

                            <aside className="col-sm-12">
                                <label>{t("Bio")}</label>
                                <h4>{userprofile.bio}</h4>
                            </aside>

                        </div>
                        {userprofile.documentsToShow.map((item, index) =>
                            < div className="row">
                                <aside className="col-sm-12">
                                    <label>{item.name} {t("Document")}</label>
                                    {item.document_url ?
                                        <h4><a href={item.document_url} target="_blank">{t("View document")}</a></h4>
                                        :
                                        <h4>{t("No document uploaded")}</h4>
                                    }
                                </aside>
                            </div>
                        )}

                    </aside>

                </div>
            </div>
            <Modal
                ariaHideApp={false}
                isOpen={editModal}
                onRequestClose={closeModal}
                className={
                    "modal-content  new_modal_content add_profile_modal-content"}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title"><span><i className="fa fa-times-circle-o" onClick={closeModal} aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                </div>
                <div className="modal-body">

                    <div className="row">
                        <aside className="col-sm-12">
                            <div className="form-group">
                                <textarea value={rejectReason} onChange={onRejectChanged} className="form-control" style={{ height: 150 }} placeholder={t("Reason for rejection")}></textarea>
                            </div>
                            <button onClick={() => rejectUser()} style={{ marginTop: 0 }} className="blue_btn yellow_btn text-uppercase">{t("Send")}</button>
                        </aside>
                    </div>



                </div>

            </Modal>
            <ToastContainer />
        </div >
    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(RequestProfile)