import React from 'react';

import footer_logo from '../../assets/images/footer_logo.png'

const Footer = () => {
    
    return (
        <footer className="footer">
        <p></p>
    </footer>
    )
}

export default Footer