import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { userService } from '../_services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";



const HowItWorks = (props: any) => {
    const { t, i18n } = useTranslation();

    const [howitworksList, setHowitworksList] = useState([{ title: "", description: "", order: 0 }]);
    const [language, setLanguage] = useState(localStorage.getItem("selectLanguage")=="ENGLISH" ? "en": "fr");
    const [type, setType] = useState('Customer');


    useEffect(() => getHowItWorks(type, language), []);

    useEffect(() => {
        var lang = localStorage.getItem("selectLanguage")=="ENGLISH" ? "en": "fr"
        setLanguage(lang)
        getHowItWorks(type, lang)
    }, [localStorage.getItem("selectLanguage")]);


    function getHowItWorks(type, language) {
        setLanguage(language)
        setType(type)
        userService.howitworks(type, language)
            .then(response => {
                console.log(response.data)
                setHowitworksList(response.data.data)
            })
            .catch(error => {
                console.log(error.response)
            });
    }


    function updateList() {
        for(var i in howitworksList){
            if(!howitworksList[i].title){
                toast.error(t("Please enter title."))
                return;
            }
            else if(!howitworksList[i].description){
                toast.error(t("Please enter description."))
                return;
            }
            else if(!howitworksList[i].order){
                toast.error(t("Please enter order."))
                return;
            }
        }
        var params ={
            updated_list: howitworksList
        }
        userService.updateHowItWorks(params, type, language)
            .then(response => {
                console.log("response", response)
                toast.success(t("List updated successfully."))
                getHowItWorks(type, language);
            })
            .catch(error => {
                console.log(error.response)
        }); 
    }

    
    function onTitleChanged(event, item, index) {
       let tmp = [...howitworksList];
       tmp[index].title=event.target.value;
       setHowitworksList(tmp);
    }

    function onDescriptionChanged(event, item, index) {
        let tmp = [...howitworksList];
        tmp[index].description=event.target.value;
        setHowitworksList(tmp);
    }

    function onOrderChanged(event, item, index) {
        let tmp = [...howitworksList];
        tmp[index].order=event.target.value;
        setHowitworksList(tmp);
    }

    return (

        <section>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 how-it-wrk-area">
                        <div className="page_header privacy-policy">
                            <h2 className="subtitle">{t("How it Works Content")}</h2>
                            <div className='how-it-wrk-select'>
                            <select
                                className="form-select"
                                onChange={(e) => getHowItWorks(e.target.value, language)}
                                value={type}
                            >
                                <option value="Customer">{t("Customer")}</option>
                                <option value="Service-provider">{t("Provider")}</option>
                            </select>
                            {/* <select
                                className="form-select"
                                onChange={(e) => getHowItWorks(type, e.target.value)}
                                value={language}
                            >
                                <option value="en">English</option>
                                <option value="fr">French</option>
                            </select> */}
                            </div>
                        </div>
                        <div className="App">
                            {howitworksList && howitworksList.length ?
                                howitworksList.map((item, index) =>
                                    <div className="row">
                                        <aside className="col-sm-5">
                                            <div className="form-group">
                                                <h4 className="subtitle">{t("Title")}</h4>
                                                <input value={item.title} onChange={(e)=>onTitleChanged(e, item,index)} className="form-control" placeholder={t("Title")} />
                                            </div>
                                        </aside>
                                        <aside className="col-sm-5">
                                            <div className="form-group">
                                                <h4 className="subtitle">{t("Description")}</h4>
                                                <input value={item.description} onChange={(e)=>onDescriptionChanged(e, item,index)} className="form-control" placeholder={t("Description")} />
                                            </div>
                                        </aside>
                                        <aside className="col-sm-2">
                                            <div className="form-group">
                                            <h4 className="subtitle">{t("Order")}</h4>
                                                <select
                                                    className="form-select"
                                                    onChange={(e) => onOrderChanged(e, item,index)}
                                                    value={item.order}
                                                >
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                </select>
                                            </div>
                                        </aside>
                                    </div>

                                )
                                : null}

                        </div>
                        <button className="save-bt sumbit-btn-bottom" onClick={updateList}>{t("Submit")}</button>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </section>



    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(HowItWorks)