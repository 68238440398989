import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
// Importing Images
import profile_image from '../assets/images/profile_image.jpg';
import { userService } from '../_services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";



const Expiry = (props: any) => {
    const { t, i18n } = useTranslation();
    const [commsion, setcommsion] = useState('');

    useEffect(() =>  callCommissionAPI(), []);

    function updateCommission() {
        let regx = /^[0-9\b]+$/;
        if (!commsion) {
            alert(t("Please enter commision percentage"))
        }
        else if (!regx.test(commsion)) {
            alert(t("Commision should include only numbers"))
        }
        else if (parseInt(commsion)>100 || parseInt(commsion)<1) {
            alert(t("Commisson value should not be greater then 100 or less then 1"))
        }
        else {
            var params = {
                commision: commsion
            }
            console.log("paramss",params)
            userService.updateCommission(params)
                .then(response => {
                    console.log("response.user", response)
                    toast.success(t("Commission updated successfuly"))
                    callCommissionAPI();
                })
                .catch(error => {
                    console.log(error.response)
                });

        }

    }

    function callCommissionAPI() {
        userService.commission()
            .then(response => {
                console.log(response.data)
                setcommsion(response.data.commission.commision)
            })
            .catch(error => {
                console.log(error.response)
            });
    }

    
    function oncommsionChanged(event) {
        setcommsion(event.target.value.replace(/ /g, ""))
    }
    
    const { error, handleSubmit, pristine, reset, submitting } = props
    return (
        <div className="tables_area">
            <h2>{t("Commission Management")}</h2>
            <div className="white_box my_profile">
                <div className="">
                   
                    <aside className="padding_20">
                        <div className="row">
                            <aside className="col-sm-6">
                                <label>{t("Commission (In percentage)")}</label>
                                <input value={commsion} onChange={oncommsionChanged} className="form-control" type="Commission (In percentage)" />
                            </aside>
                          
                        </div>
                       
                        <button onClick={() => updateCommission()} className="blue_btn yellow_btn text-uppercase">{t("Update")}</button>
                    </aside>
                </div>
            </div>
            <ToastContainer />
        </div>

    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(Expiry)